import {
  SearchOutlined,
  StarTwoTone,
  ScheduleTwoTone,
  AccountBookTwoTone,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  PageHeader,
  Table,
  Tag,
} from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";
import dayjs from "dayjs";
import moment from "moment";
import "moment/locale/zh-cn";
import React from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { pagePayment } from "../../../../actions/payment";
import { PageData } from "../../../../constants/globa";
import { IPayment } from "../../../../constants/payment";
import {
  host,
  pagePayment as RequestPage,
  queryPaymentPage,
} from "../../../../utils/api";
import { ServerRes } from "../../../../utils/request";

import SPayment from "../statistics/payment";

const { RangePicker } = DatePicker;

const s2p = ({ Payment }: { Payment: PageData<IPayment> }) => ({
  Payment,
});

const d2p = (dispatch: any) => ({
  pagePayment: (page: PageData<IPayment>) => dispatch(pagePayment(page)),
});

type IProps = ReturnType<typeof s2p> &
  ReturnType<typeof d2p> &
  RouteComponentProps;

const Payment: React.FC<IProps> = (props: IProps) => {
  const [itd, setItd] = React.useState(false);
  const [sp, setSp] = React.useState(false);
  const [moments, setMoments] = React.useState<moment.Moment[]>([]);
  const [license, setLicense] = React.useState("");

  const fetchData = React.useCallback(
    (page, size, moments: moment.Moment[]) => {
      setSp(true);
      RequestPage(page, size, moments)
        .then((res: Response) => {
          return res.json();
        })
        .then((res: ServerRes<PageData<IPayment>>) => {
          if (res.Error !== 0) {
            return;
          }
          props.pagePayment(res.Result);
          setItd(true);
        })
        .catch((e) => {
          message.error(e);
        })
        .finally(() => {
          setSp(false);
        });
    },
    [setSp]
  );

  React.useEffect(() => {
    document.title = "支付列表";
    if (itd || props.Payment.page > 0) {
      return;
    }
    fetchData(props.Payment.page + 1, props.Payment.size, moments);
  }, [itd, setItd, props]);

  const [licenseForm] = Form.useForm();

  const onLicenseFormFinish = React.useCallback(
    (values: Storage) => {
      setSp(true);
      queryPaymentPage(1, props.Payment.size, { license: values.license })
        .then((res: Response) => {
          return res.json();
        })
        .then((res: ServerRes<PageData<IPayment>>) => {
          if (res.Error !== 0) {
            return;
          }
          props.pagePayment(res.Result);
          setItd(true);
        })
        .catch((e) => {
          message.error(e);
        })
        .finally(() => {
          setSp(false);
        });
    },
    [props, fetchData, setItd]
  );

  const columns = React.useMemo(() => {
    return [
      {
        title: "服务二维码",
        render: (item: IPayment) =>
          item.sid ? (
            <Avatar
              size={48}
              src={`${host}/api/wx/service/image/${item.sid}`}
            />
          ) : (
            <Avatar
              size={48}
              shape="square"
              style={{ color: "#FFF", backgroundColor: "#1890ff" }}
            >
              即时支付
            </Avatar>
          ),
        width: "84px",
      },
      {
        title: "车牌号码",
        dataIndex: "license",
        render: (license: string) => <span>{license}</span>,
        filterDropdown: (_: FilterDropdownProps) => (
          <div style={{ padding: 8 }}>
            <Form
              form={licenseForm}
              onFinish={onLicenseFormFinish}
              onReset={() => {
                licenseForm.resetFields();
                if (license != "") {
                  fetchData(1, props.Payment.size, []);
                }
              }}
            >
              <Form.Item
                name="license"
                style={{ marginBottom: "0" }}
                rules={[
                  { required: true, message: "请输入车牌号码" },
                  {
                    pattern: /^[\s\S]*.*[^\s][\s\S]*$/,
                    message: "请把空格删掉",
                  },
                ]}
              >
                <Input
                  name="license"
                  placeholder="在此输入车牌号码"
                  style={{ width: 188, marginBottom: 8, display: "block" }}
                  value={license}
                  onChange={(e) => setLicense(e.currentTarget.value)}
                />
              </Form.Item>
              <Form.Item style={{ marginBottom: "0" }}>
                <Button
                  htmlType="submit"
                  type="primary"
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90, marginRight: 8 }}
                >
                  查找
                </Button>
                <Button size="small" style={{ width: 90 }} htmlType="reset">
                  重置
                </Button>
              </Form.Item>
            </Form>
          </div>
        ),
      },
      {
        title: "状态",
        dataIndex: "status",
        render: (status: number) =>
          ["未知", "正在支付", "已支付", "已取消", "已退款"][status],
      },
      {
        key: "total_fee",
        title: "金额",
        dataIndex: "total_fee",
        render: (fee: number) => <span>￥{fee / 100}</span>,
      },
      {
        key: "remarks",
        title: "备注",
        dataIndex: "remarks",
      },
      {
        key: "start_at",
        title: "开始支付时间",
        dataIndex: "start_at",
        render: (time: number) => (
          <span>
            {dayjs(time * 1000).format("YYYY-MM-DD HH:mm:ss")}{" "}
            {dayjs(time * 1000).isSame(dayjs(), "day") ? (
              <Tag color="#1890ff">今天</Tag>
            ) : null}
          </span>
        ),
      },
      {
        key: "ends_at",
        title: () => {
          if (moments.length === 2) {
            let start = moments[0].format("YYYY-MM-DD ");
            let end = moments[1].format("YYYY-MM-DD ");
            return `完成支付时间 (${start} - ${end})`;
          }
          return "完成支付时间";
        },
        dataIndex: "ends_at",
        render: (time: number) =>
          time ? (
            <span>
              {dayjs(time * 1000).format("YYYY-MM-DD HH:mm:ss")}{" "}
              {dayjs(time * 1000).isSame(dayjs(), "day") ? (
                <Tag color="#1890ff">今天</Tag>
              ) : null}
            </span>
          ) : null,
        filterDropdown: (p: FilterDropdownProps) => (
          <div style={{ padding: 8 }}>
            <RangePicker
              ranges={{
                今天: [moment().startOf("day"), moment()],
                本周: [moment().startOf("week"), moment()],
                本月: [moment().startOf("month"), moment()],
                本季: [moment().startOf("quarter"), moment()],
                今年: [moment().startOf("year"), moment()],
              }}
              value={moments as [moment.Moment, moment.Moment]}
              onChange={(mnts: moment.Moment[]) => {
                setMoments(mnts == null ? [] : mnts);
                if (mnts.length == 2) {
                  mnts[0] = mnts[0].startOf("day");
                  mnts[1] = mnts[1].endOf("day");
                }
                fetchData(1, props.Payment.size, mnts == null ? [] : mnts);
                p.confirm();
              }}
            />
            <Button
              type="default"
              icon={<SearchOutlined />}
              style={{ width: 90, marginLeft: 8 }}
              onClick={() => {
                if (moments.length === 2) {
                  setMoments([]);
                  fetchData(1, props.Payment.size, []);
                  p.confirm();
                }
              }}
            >
              重置
            </Button>
          </div>
        ),
      },
      {
        title: "操作",
        render: (item: IPayment) =>
          item.sid == "" ? null : (
            <Link to={`/service/details/${item.sid}`}>查看服务详情</Link>
          ),
      },
    ];
  }, [
    moments,
    setMoments,
    fetchData,
    licenseForm,
    license,
    setLicense,
    onLicenseFormFinish,
  ]);

  let pageTotal = 0;

  props.Payment.payload.map((p) => {
    pageTotal += p.status === 2 ? p.total_fee / 100 : 0;
  });

  return (
    <div>
      <PageHeader
        ghost={false}
        title="支付列表"
        extra={[
          <Button
            key="1"
            size="large"
            type="dashed"
            shape="round"
            icon={<StarTwoTone color="#52c41a" />}
          >
            当前页面： ￥{pageTotal}元
          </Button>,
          <Button
            key="2"
            size="large"
            type="dashed"
            shape="round"
            icon={<ScheduleTwoTone />}
          >
            今天：￥{props.Payment.statistic.today / 100}元
          </Button>,
          <Button
            key="3"
            size="large"
            type="dashed"
            shape="round"
            icon={<AccountBookTwoTone />}
          >
            所有：￥
            {props.Payment.statistic.history
              ? props.Payment.statistic.history / 100
              : 0}
            元
          </Button>,
          <Button
            key="4"
            size="large"
            type="primary"
            icon={<SyncOutlined spin={sp} />}
            onClick={() => fetchData(1, props.Payment.size, [])}
          >
            刷新
          </Button>,
        ]}
      />

      <div
        style={{
          backgroundColor: "#FFF",
          textAlign: "right",
          paddingRight: "24px",
        }}
      >
        <h3>30天支付趋势</h3>
        <SPayment />
      </div>
      <Divider />

      <Table
        loading={sp}
        dataSource={props.Payment.payload}
        columns={columns}
        rowKey="sid"
        pagination={{
          total: props.Payment.total,
          pageSize: props.Payment.size,
          current: props.Payment.page,
          onChange: (page, size) => fetchData(page, size, moments),
          onShowSizeChange: (page, size) => fetchData(page, size, moments),
          showTotal: (total: number, range: [number, number]) => (
            <span>
              共{total}条记录，当前第{range[0]} - {range[1]}条
            </span>
          ),
        }}
      />
    </div>
  );
};

export default connect(s2p, d2p)(withRouter(Payment));
