export const SET_CONFIGS = 'SET_CONFIGS';

// 车型及起步价
export type IStartingCfg = {
    id: number;
    model: string;     // 车型
    daytime: number;   // 白天起步价
    night: number;     // 夜间起步价
}

// 距离收费配置
export type IDeliverCfg = {
    id: number;
    model: number;    // 车型
    minimal: number;  // 最低距离(米)
    maximum: number;  // 最高距离(米)
    daytime: number;  // 白天/km
    night: number;    // 夜间/km
}

// 短信通知配置
export type INotificationsCfg = {
    id: number;
    name: string;    // 姓名
    phone: string;   // 手机号码
    tags: string[];  // 通知类型
}

// 时段配置
export type IPeroidCfg = {
    id: number;
    type: string;     // 类型,daytime：白天
    from: number;     // 时段开始，从0点的秒数开始(零点零分为0)
    to: number;       // 时段结束
}

// 其他单项收费
export type IRescueCfg = {
    id: number;
    wheel: number;     // 单个辅助轮收费
    normal: number;    // 现场救援普通难度收费
    hard: number;      // 现场救援较难难度收费
    basement: number;  // 地库救援收费
}


// 服务配置
export type ServerConfig = {
    delivers: IDeliverCfg[];
    notifications: INotificationsCfg[];
    period: IPeroidCfg;
    rescue: IRescueCfg;
    starting: IStartingCfg[];
}
