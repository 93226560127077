import { Button, Divider, Form, Input, Layout, Spin, message } from "antd";
import { connect } from 'react-redux';
import { Store } from "rc-field-form/lib/interface";
import { Redirect } from 'react-router-dom';
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import "./login.css";
import { login_args, IUser } from "../../constants/login";
import { login } from "../../utils/api";
import { ServerRes } from "../../utils/request";
import { setUser } from "../../actions/auth";


const s2p = ({ User }: { User: IUser | null }) => ({
    User
});

const d2p = (dispatch: any) => ({
    setUser: (user: IUser) => dispatch(setUser(user)),
})
type IProps = ReturnType<typeof s2p> & ReturnType<typeof d2p> & RouteComponentProps

const Login: React.FC<IProps> = (props: IProps) => {
    const [spin, setSpin] = React.useState(false);

    React.useEffect(() => {
        document.title = "登录";
    });

    // 验证规则
    const rules = React.useMemo(() => {
        return {
            username: [
                {
                    required: true,
                    message: "请输入您的帐号"
                }
            ],
            password: [
                {
                    required: true,
                    message: "请输入您的密码"
                }
            ]
        };
    }, []);


    const onFormFinish = React.useCallback(
        async (values: Store) => {
            let data = values as login_args;
            setSpin(true);
            try {
                const response = await login(data);
                setSpin(false);
                const result: ServerRes<any> = await response.json();

                if (result.Error !== 0) {
                    message.error(result.Desc);
                    return;
                } else {
                    message.success("登录成功");
                    props.setUser(result.Result)
                    window.localStorage.setItem('user', JSON.stringify(result.Result));
                }
            } catch (e) {
                message.error("登录失败");
                setSpin(false);
            }
        },
        [props, setSpin]
    );

    if (props.User != null) {
        return <Redirect to="/" />
    }

    return (
        <Layout className="login-container">
            <Layout.Content className="login-body">
                <Spin spinning={spin} tip="登录中">
                    <div className="form-wrapper">
                        <h2>拖车侠小程序服务管理系统</h2>
                        <Divider />
                        <Form name="login" onFinish={onFormFinish} layout="vertical">
                            <Form.Item label="帐号" name="username" rules={rules.username}>
                                <Input placeholder="请在此输入帐号" size="large" />
                            </Form.Item>

                            <Form.Item label="密码" name="password" rules={rules.password}>
                                <Input.Password placeholder="请在此输入密码" size="large" />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    登录
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Spin>
            </Layout.Content>
            <Layout.Footer className="login-footer">
                贵州拖车侠科技服务有限公司
            </Layout.Footer>
        </Layout>
    );
};


export default connect(s2p, d2p)(withRouter(Login));
