import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  Button,
  Divider,
  PageHeader,
  Table,
  message,
  Modal,
  Avatar,
} from "antd";
import { PageData } from "../../../../constants/globa";
import { IServeyor } from "../../../../constants/serveyor";
import {
  pageServeyor as queryServeyorPage,
  getServeyorQR,
} from "../../../../utils/dispatcher";
import { ServerRes } from "../../../../utils/request";
import { PageServeyor } from "../../../../actions/serveyor";
import { IDispatcher } from "../../../../constants/dispatcher";

const s2p = ({ Serveyor }: { Serveyor: PageData<IServeyor> }) => ({
  Serveyor,
});

const d2p = (d: any) => ({
  PageServeyor: (page: PageData<IServeyor>) => d(PageServeyor(page)),
});

type IProps = RouteComponentProps &
  ReturnType<typeof s2p> &
  ReturnType<typeof d2p>;

const Serveyor: React.FC<IProps> = (props: IProps) => {
  // 是否已经初始化
  const initialed = React.useState(false);
  const tbSpin = React.useState(false);

  const showQrImage = React.useCallback((serveyor: IServeyor) => {
    let modal = Modal.info({
      content: (
        <div>
          <Avatar
            size={240}
            src={getServeyorQR(serveyor.did)}
            style={{ borderRadius: 0 }}
          />
        </div>
      ),
      centered: true,
      okText: "关闭",
      title: "扫描二维码进行绑定",
      onOk: () => modal.destroy(),
    });
  }, []);

  const colunmes = React.useMemo(() => {
    return [
      {
        title: "姓名",
        dataIndex: "name",
      },
      {
        title: "联系电话",
        dataIndex: "contact",
      },
      {
        title: "所属公司",
        dataIndex: "company_name",
      },
      {
        title: "状态",
        render: (row: IDispatcher) => {
          if (row.openid == "") {
            return "未绑定";
          }
          return "已绑定";
        },
      },
      {
        title: "微信头像",
        dataIndex: "avatar_url",
        render: (avatar: string) => (
          <Avatar src={avatar} size={48} shape="square" />
        ),
      },
      {
        title: "微信昵称",
        dataIndex: "nickname",
      },
      {
        title: "操作",
        render: (row: IServeyor) => {
          return (
            <div>
              <Button onClick={() => showQrImage(row)}>绑定</Button>
            </div>
          );
        },
      },
    ];
  }, [showQrImage]);

  const pageData = React.useCallback(
    (page: number, size: number, partner: string = "") => {
      if (tbSpin[0]) {
        return;
      }
      tbSpin[1](true);
      queryServeyorPage(page, size, partner)
        .then((res) => {
          return res.json();
        })
        .then((res: ServerRes<PageData<IServeyor>>) => {
          if (res.Error == "C000000") {
            props.PageServeyor(res.Result);
            message.success("获取成功");
          }
        })
        .catch((err: Error) => {
          console.log(err);
          message.error(`获取失败：${err.message}`);
        })
        .finally(() => {
          tbSpin[1](false);
        });
    },
    [tbSpin]
  );

  const partner = React.useMemo(() => {
    let partner = "";
    let { search } = props.location;
    let searchs = search.split(/^\?$/);
    if (searchs.length === 1) {
      let queries = searchs[0].split(/(\w+)=/);
      let index = -1;
      queries.forEach((v: string, i: number) => {
        if (v.toLowerCase() === "partner") {
          index = i + 1;
        }
      });
      if (index > -1) {
        partner = queries[index];
      }
    }
    return partner;
  }, [props]);

  React.useEffect(() => {
    let p = window.sessionStorage.getItem("partner");
    if ((!initialed[0] && props.Serveyor.page == 0) || p !== partner) {
      pageData(1, props.Serveyor.size, partner);
      window.sessionStorage.setItem("partner", partner);
      initialed[1](true);
    }
  }, [initialed, props, pageData, partner]);

  const { Serveyor } = props;
  let title = "查勘员管理";
  let extra = [];
  if (partner !== "") {
    title = `查勘员管理 [${partner}] `;
    extra.push(
      <Button
        key="all"
        type="primary"
        onClick={() => {
          props.history.replace("/serveyor/list");
          pageData(1, props.Serveyor.size, "");
        }}
      >
        查看所有
      </Button>
    );
  }

  return (
    <div>
      <PageHeader title={title} ghost={false} extra={extra} />
      <Divider />
      <Table
        loading={tbSpin[0]}
        columns={colunmes}
        dataSource={Serveyor.payload}
        rowKey="did"
        pagination={{
          current: Serveyor.page,
          pageSize: Serveyor.size,
          total: Serveyor.total,
          showQuickJumper: true,
          showSizeChanger: true,
          onChange: (page: number, size: number) => {
            pageData(page, size, partner);
          },
        }}
      />
    </div>
  );
};

export default withRouter(connect(s2p, d2p)(Serveyor));
