import { ServerConfig, SET_CONFIGS } from "../constants/configs";

const initialState: ServerConfig | null = null;

type Action = {
    type: string;
    configs: ServerConfig
}

export const Configs = (state = initialState, action: Action) => {
    switch (action.type) {
        case SET_CONFIGS:
            return { ...action.configs };
        default:
            return state;
    }
};
