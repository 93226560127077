import {
  SearchOutlined,
  StarTwoTone,
  ScheduleTwoTone,
  AccountBookTwoTone,
  SyncOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Divider,
  DatePicker,
  Input,
  message,
  PageHeader,
  Table,
  Tag,
  Form,
  Popconfirm,
  Tooltip,
} from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";
import dayjs from "dayjs";
import moment from "moment";
import "moment/locale/zh-cn";
import React from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { pageService } from "../../../../actions/service";
import { PageData } from "../../../../constants/globa";
import { Service as IService, STATUS } from "../../../../constants/service";
import {
  host,
  pageService as RequestPage,
  queryServicePage,
  deleteService,
} from "../../../../utils/api";
import { ServerRes } from "../../../../utils/request";
import { Store } from "antd/lib/form/interface";

import SService from "../statistics/service";

const { RangePicker } = DatePicker;

const s2p = ({ Service }: { Service: PageData<IService> }) => ({
  Service,
});

const d2p = (dispatch: any) => ({
  pageService: (page: PageData<IService>) => dispatch(pageService(page)),
});

type IProps = ReturnType<typeof s2p> &
  ReturnType<typeof d2p> &
  RouteComponentProps;

const Service: React.FC<IProps> = (props: IProps) => {
  const [itd, setItd] = React.useState(false);
  const [sp, setSp] = React.useState(false);
  const [moments, setMoments] = React.useState<moment.Moment[]>([]);
  const [license, setLicense] = React.useState<string>("");

  const fetchData = React.useCallback(
    (page, size, moments) => {
      setSp(true);
      RequestPage(page, size, moments)
        .then((res: Response) => {
          return res.json();
        })
        .then((res: ServerRes<PageData<IService>>) => {
          if (res.Error !== 0) {
            return;
          }
          props.pageService(res.Result);
          setItd(true);
        })
        .catch((e) => {
          message.error(e);
        })
        .finally(() => {
          setSp(false);
          /* setFiltered(false); */
        });
    },
    [setSp, props]
  );

  React.useEffect(() => {
    document.title = "服务列表";
    if (itd || props.Service.page > 0) {
      return;
    }
    fetchData(props.Service.page + 1, props.Service.size, []);
  }, [fetchData, itd, setItd, props]);

  const statusColor = React.useMemo(
    () => ["#F0F0F0", "pink", "green", "purple", "orange"],
    []
  );

  const showDetails = React.useCallback(
    (service: IService) => {
      window.localStorage.setItem(
        `details.${service.sid}`,
        JSON.stringify(service)
      );
      props.history.push(`/service/details/${service.sid}`);
    },
    [props]
  );

  const [licenseForm] = Form.useForm();
  const onLicenseFormFinish = React.useCallback(
    (values: Store) => {
      setSp(true);
      queryServicePage(1, props.Service.size, values as { license: string })
        .then((res) => {
          return res.json();
        })
        .then((res: ServerRes<PageData<IService>>) => {
          if (res.Error !== 0) {
            return;
          }
          props.pageService(res.Result);
          setItd(true);
        })
        .catch(() => {})
        .finally(() => {
          setSp(false);
        });
    },
    [props, setItd]
  );

  const confirmDelete = React.useCallback(
    (sid: string, id: number) => {
      setSp(true);
      deleteService(sid, id)
        .then((res) => res.json())
        .then((res: ServerRes<null>) => {
          if (res.Error !== 0) {
            message.error("删除失败");
          } else {
            message.success("删除成功");
            fetchData(props.Service.page, props.Service.size, moments);
          }
        })
        .catch(() => {
          message.error("删除失败");
        })
        .finally(() => {
          setSp(false);
        });
    },
    [setSp, props, fetchData, moments]
  );

  const columns = React.useMemo(() => {
    return [
      {
        title: "服务二维码",
        render: (item: IService) =>
          item.sid ? (
            <Avatar
              size={48}
              src={`${host}/api/wx/service/image/${item.sid}`}
            />
          ) : (
            "即时支付"
          ),
        width: "84px",
      },
      {
        title: "客户",
        render: (item: IService) => (
          <span>
            {item.name} {item.gender}
          </span>
        ),
      },
      {
        key: "phone",
        title: "联系电话",
        dataIndex: "phone",
      },
      {
        key: "license",
        title: "车牌号码",
        dataIndex: "license",
        filterDropdown: (_: FilterDropdownProps) => (
          <div style={{ padding: 8 }}>
            <Form
              form={licenseForm}
              onFinish={onLicenseFormFinish}
              onResetCapture={() => {
                licenseForm.resetFields();
                if (license != "") {
                  fetchData(1, props.Service.size, []);
                }
              }}
            >
              <Form.Item
                name="license"
                style={{ marginBottom: "0" }}
                rules={[
                  { required: true, message: "请输入车牌号码" },
                  {
                    pattern: /^[\s\S]*.*[^\s][\s\S]*$/,
                    message: "请把空格删掉",
                  },
                ]}
              >
                <Input
                  name="license"
                  placeholder="在此输入车牌号码"
                  value={license}
                  style={{ width: 188, marginBottom: 8, display: "block" }}
                  onChange={(e) => setLicense(e.currentTarget.value)}
                />
              </Form.Item>
              <Form.Item style={{ marginBottom: "0" }}>
                <Button
                  htmlType="submit"
                  type="primary"
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90, marginRight: 8 }}
                >
                  查找
                </Button>
                <Button size="small" style={{ width: 90 }} htmlType="reset">
                  重置
                </Button>
              </Form.Item>
            </Form>
          </div>
        ),
      },
      {
        key: "model",
        title: "车型",
        dataIndex: "model",
      },
      {
        key: "created_at",
        title: () => {
          if (moments.length === 2) {
            let start = moments[0].format("YYYY-MM-DD ");
            let end = moments[1].format("YYYY-MM-DD ");
            return `请求服务时间 (${start} - ${end})`;
          }
          return "请求服务时间";
        },
        dataIndex: "created_at",
        render: (time: number) => (
          <span>
            {dayjs(time * 1000).format("YYYY-MM-DD HH:mm:ss")}{" "}
            {dayjs(time * 1000).isSame(dayjs(), "day") ? (
              <Tag color="#1890ff">今天</Tag>
            ) : null}
          </span>
        ),
        filterDropdown: (p: FilterDropdownProps) => (
          <div style={{ padding: 8 }}>
            <RangePicker
              ranges={{
                今天: [moment().startOf("day"), moment()],
                本周: [moment().startOf("week"), moment()],
                本月: [moment().startOf("month"), moment()],
                本季: [moment().startOf("quarter"), moment()],
                今年: [moment().startOf("year"), moment()],
              }}
              value={moments as [moment.Moment, moment.Moment]}
              onChange={(mnts: moment.Moment[]) => {
                setMoments(mnts == null ? [] : mnts);
                if (mnts.length == 2) {
                  mnts[0] = mnts[0].startOf("day");
                  mnts[1] = mnts[1].endOf("day");
                }
                fetchData(1, props.Service.size, mnts == null ? [] : mnts);
                p.confirm();
              }}
            />
            <Button
              type="default"
              icon={<SearchOutlined />}
              style={{ width: 90, marginLeft: 8 }}
              onClick={() => {
                if (moments.length === 2) {
                  setMoments([]);
                  p.confirm();
                  fetchData(1, props.Service.size, []);
                }
              }}
            >
              重置
            </Button>
          </div>
        ),
      },
      {
        key: "status",
        title: "服务状态",
        dataIndex: "status",
        render: (status: number) => (
          <span>
            <Badge color={statusColor[status]}>{STATUS[status]}</Badge>
          </span>
        ),
      },
      {
        title: "操作",
        render: (item: IService) => {
          if (item.sid == null) {
            return null;
          }

          const diff = new Date().getTime() / 1000 - item.created_at;

          if (item.status === 2 || diff < 24 * 3600 || item.status === 3) {
            return (
              <span>
                <Link
                  to={`/service/details/${item.sid}`}
                  onClick={(e) => {
                    e.preventDefault();
                    showDetails(item);
                  }}
                >
                  查看服务详情
                </Link>
              </span>
            );
          }

          return (
            <span>
              <Link
                to={`/service/details/${item.sid}`}
                onClick={(e) => {
                  e.preventDefault();
                  showDetails(item);
                }}
              >
                查看服务详情
              </Link>
              <Divider type="vertical" />
              <Popconfirm
                title=" 确定删除无效订单？"
                onConfirm={() => confirmDelete(item.sid, item.id)}
              >
                <Tooltip
                  title="这个服务已经超过24小时"
                  trigger="hover"
                  placement="right"
                >
                  <Button type="dashed" danger={true}>
                    删除
                  </Button>
                </Tooltip>
              </Popconfirm>
            </span>
          );
        },
      },
    ];
  }, [
    showDetails,
    moments,
    setMoments,
    licenseForm,
    onLicenseFormFinish,
    license,
    setLicense,
    confirmDelete,
  ]);

  return (
    <div>
      <PageHeader
        ghost={false}
        title="服务列表"
        extra={[
          <Button
            key="1"
            size="large"
            type="dashed"
            shape="round"
            icon={<StarTwoTone color="#52c41a" />}
          >
            当前页面：{props.Service.payload.length}次
          </Button>,
          <Button
            key="2"
            size="large"
            type="dashed"
            shape="round"
            icon={<ScheduleTwoTone />}
          >
            今天：{props.Service.statistic.today}次
          </Button>,
          <Button
            key="3"
            size="large"
            type="dashed"
            shape="round"
            icon={<AccountBookTwoTone />}
          >
            所有：{props.Service.total}次
          </Button>,
          <Button
            key="4"
            size="large"
            type="primary"
            shape="round"
            icon={<FileExcelOutlined />}
            onClick={() => {
              props.history.push("/service/excel");
            }}
          >
            导出到Excel
          </Button>,
          <Button
            key="5"
            size="large"
            type="primary"
            icon={<SyncOutlined spin={sp} />}
            onClick={() => fetchData(1, props.Service.size, [])}
          >
            刷新
          </Button>,
        ]}
      />
      <div
        style={{
          backgroundColor: "#FFF",
          textAlign: "right",
          paddingRight: "24px",
        }}
      >
        <h3>30天服务趋势</h3>
        <SService />
      </div>
      <Divider />
      <Table
        loading={sp}
        dataSource={props.Service.payload}
        columns={columns}
        rowKey="sid"
        pagination={{
          total: props.Service.total,
          pageSize: props.Service.size,
          current: props.Service.page,
          onChange: (page: number, size: number) => {
            fetchData(page, size, moments);
          },
          onShowSizeChange: (page: number, size: number) => {
            fetchData(page, size, moments);
          },
          showTotal: (total: number, range: [number, number]) => (
            <span>
              共{total}条记录，当前第{range[0]} - {range[1]}条
            </span>
          ),
        }}
      />
    </div>
  );
};

export default connect(s2p, d2p)(withRouter(Service));
