import { combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import { User } from "./auth";
import { Service } from "./service";
import { Payment } from "./payment";
import { Credit } from "./credit";
import { Configs } from "./configs";
import { Buyer } from "./buyer";
import { Driver } from "./driver";
import { Dispatcher } from "./dispatcher";
import { Partner } from "./partner";
import { Serveyor } from "./serveyor";

export default createStore(
  combineReducers({
    User,
    Service,
    Payment,
    Credit,
    Configs,
    Buyer,
    Driver,
    Dispatcher,
    Partner,
    Serveyor,
  }),
  composeWithDevTools()
);
