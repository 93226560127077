import React from "react";
import { Result, Button } from "antd";

const Index: React.FC = () => {
  return (
    <div>
      <Result
        status="success"
        title="拖车侠小程序管理系统"
        subTitle="数字救援 智慧可见"
        extra={[
          <Button href="/#/service/service" type="primary" key="console">
            打开服务列表
          </Button>,
          <Button href="/#/config/charge" key="config">
            打开配置项
          </Button>,
        ]}
      />
    </div>
  );
};

export default Index;
