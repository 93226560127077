import React from "react";
import { Button, Divider, Result, DatePicker, Spin } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import moment, { Moment } from "moment";
import "moment/locale/zh-cn";

import { exportExcel } from "../../../../utils/api";
import { DownloadFile } from "../../../../utils/file";

const { RangePicker } = DatePicker;

const ExcelComp = () => {
  const [range, setRange] = React.useState<number[]>([]);
  const [sp, setSp] = React.useState(false);

  const _export = React.useCallback(() => {
    if (range.length === 0) {
      return;
    }
    setSp(true);
    exportExcel(range[0], range[1])
      .then((r) => {
        let name = r.headers.get("Content-Type");
        if (name != "application/vnd.ms-excel") {
          return;
        }
        DownloadFile(r, `拖车侠服务列表(${range[0]}-${range[1]}).xlsx`);
        setRange([]);
      })
      .finally(() => {
        setSp(false);
      });
  }, [range, setRange, setSp]);

  return (
    <Spin spinning={sp}>
      <Result
        status="info"
        title="导出服务详情到Excel"
        subTitle="在下面的按钮中选择日期"
        extra={[
          <RangePicker
            defaultValue={
              range.length === 0 ? null : [moment(range[0]), moment(range[1])]
            }
            ranges={{
              今天: [moment().startOf("day"), moment()],
              昨天: [
                moment().startOf("day").subtract(1, "days"),
                moment().startOf("day").subtract(1, "days").endOf("d"),
              ],
              上周: [
                moment().startOf("week").subtract(1, "weeks"),
                moment().startOf("week").subtract(1, "w").endOf("w"),
              ],
              上月: [
                moment().startOf("month").subtract(1, "M"),
                moment().startOf("month").subtract(1, "M").endOf("month"),
              ],
            }}
            onChange={(moments: [Moment, Moment] | null) => {
              if (moments === null) {
                setRange([]);
                return;
              }
              let start = moments[0];
              let end = moments[1];

              const s = start.startOf("d").toDate().getTime() / 1000;
              const e = Math.ceil(end.endOf("d").toDate().getTime() / 1000);
              setRange([s, e]);
            }}
          />,
          <Divider />,
          <Button
            size="large"
            icon={<DownloadOutlined />}
            type="primary"
            disabled={range.length != 2}
            onClick={() => _export()}
          >
            导出
          </Button>,
        ]}
      />
    </Spin>
  );
};

export default ExcelComp;
