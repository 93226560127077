import { PageData } from "../constants/globa";
import { IDriver, PAGE_DRIVER, PATCH_DRIVER } from "../constants/driver";

export const pageDriver = (page: PageData<IDriver>) => ({
  type: PAGE_DRIVER,
  page,
});

export const patchDriver = (driver: IDriver) => ({
  type: PATCH_DRIVER,
  driver,
});
