export const PAGE_DISPATCHER = "PAGE_DISPATCHER";

export interface IDispatcher {
  id: number;
  did: string;
  account: string;
  name: string;
  contact: string;
  openid: string;
  nickname: string;
  avatar_url: string;
  locked: boolean;
  deleted: boolean;
  created_at: number;
  updated_at: number;
}
