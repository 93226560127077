export function RT(req: Request, timeout: number): Promise<Response> {
  return new Promise<Response>(function (resolve, reject) {
    const timer = setTimeout(() => {
      reject("Request timeout");
      clearTimeout(timer);
    }, timeout);

    fetch(req)
      .then((r: Response) => {
        resolve(r);
      })
      .catch((e) => {
        reject(e);
      })
      .finally(() => {
        clearTimeout(timer);
      });
  });
}

export interface ServerRes<T> {
  Action: string;
  Error: number | string;
  Desc: string;
  Version: string;
  Result: T;
}
