import {
  PoweroffOutlined,
  FormOutlined,
  WechatOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Divider,
  PageHeader,
  Steps,
  Form,
  Input,
  Result,
  Row,
  Col,
  Spin,
  message,
} from "antd";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import {
  registerDispatcher,
  getDispatcherQR,
} from "../../../../utils/dispatcher";
import { ServerRes } from "../../../../utils/request";
import { IDispatcher } from "../../../../constants/dispatcher";

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};

const buttonLayout = {
  wrapperCol: { offset: 20, span: 4 },
};

const pt = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;

type InitialBasic = {
  id?: number;
  did?: string;
  name?: string;
  contact?: string;
  account?: string;
  password?: string;
  vPassword?: string;
};

type ChildProps = {
  Next: React.Dispatch<React.SetStateAction<number>>;
  OnCommit?: React.Dispatch<React.SetStateAction<InitialBasic>>;
  Current: number;
  IsFinnal: boolean;
  InitialBasic?: InitialBasic;
  RegisterDispatcher?: (args: InitialBasic) => Promise<void | IDispatcher>;
};

//////// 三个步骤组建
const BasicForm: React.FC<ChildProps> = (props: ChildProps) => {
  const [form] = Form.useForm();
  const onFinish = React.useCallback(
    (values: InitialBasic) => {
      props
        .RegisterDispatcher(values)
        .then((dispatcher: IDispatcher) => {
          if (dispatcher == null) {
            return;
          }

          let next = {
            ...values,
            ...{ id: dispatcher.id, did: dispatcher.did },
          };
          props.OnCommit(next);
          props.Next(props.Current + 1);
        })
        .catch((e) => {
          console.error(e);
          message.error(`注册失败：${e}`);
        });
    },
    [props]
  );

  return (
    <div className="dispatcher-basic-container">
      <Form
        form={form}
        {...layout}
        initialValues={props.InitialBasic}
        onFinish={onFinish}
      >
        <Form.Item
          label="姓名"
          name="name"
          {...tailLayout}
          rules={[{ required: true, message: "请输入调度员姓名" }]}
        >
          <Input placeholder="调度员姓名" size="large" />
        </Form.Item>
        <Form.Item
          label="手机号码"
          name="contact"
          {...tailLayout}
          rules={[
            { required: true, message: "请输入调度员手机号码" },
            { pattern: pt, message: "请输入正确的手机号码" },
          ]}
        >
          <Input placeholder="调度员手机号码" size="large" />
        </Form.Item>
        <Form.Item
          label="帐号"
          name="account"
          {...tailLayout}
          rules={[{ required: true, message: "请输入调度员登录帐号" }]}
        >
          <Input placeholder="调度员登录帐号" size="large" />
        </Form.Item>
        <Form.Item
          label="登录密码"
          name="password"
          {...tailLayout}
          rules={[{ required: true, message: "请输入调度员登录密码" }]}
        >
          <Input.Password placeholder="调度员登录密码" size="large" />
        </Form.Item>
        <Form.Item
          label="确认登录密码"
          name="vPassword"
          dependencies={["password"]}
          {...tailLayout}
          rules={[
            { required: true, message: "请输入调度员确认密码" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("确认密码与密码不匹配！");
              },
            }),
          ]}
        >
          <Input.Password placeholder="确认调度员登录" size="large" />
        </Form.Item>
        <Form.Item {...buttonLayout}>
          <Button type="primary" htmlType="submit" block={true} size="large">
            下一步
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const Wechat: React.FC<ChildProps> = (props: ChildProps) => {
  return (
    <div>
      <Row>
        <Col span={6} offset={9}>
          <Result
            status="success"
            title="注册成功"
            subTitle="请调度员打开拖车侠小程序，在[我的]->[拖车侠调度员]中扫描下面的二维码绑定即可"
            extra={
              <Avatar
                src={getDispatcherQR(props.InitialBasic.did)}
                size={440}
                style={{ borderRadius: 0, border: "1px solid #F0F0F0" }}
              />
            }
          />
        </Col>
      </Row>
    </div>
  );
};

type IProps = RouteComponentProps;

const AddDispatcher: React.FC<IProps> = (props: IProps) => {
  const [current, nextStep] = React.useState(0);
  const [sp, setSp] = React.useState(false);

  // 初始化数据
  const [basic, setBasic] = React.useState<InitialBasic>({});

  const commitDispatch = React.useCallback(
    (args: InitialBasic) => {
      // 如果正在提交，则此次提交无效
      if (sp) {
        return;
      }
      setSp(true);

      return registerDispatcher({
        account: args.account,
        name: args.name,
        contact: args.contact,
        password: args.password,
        vPassword: args.vPassword,
      })
        .then((res) => {
          return res.json();
        })
        .then((res: ServerRes<IDispatcher>) => {
          if (res.Error !== "C000000") {
            throw new Error(res.Desc);
          }
          return res.Result;
        })
        .catch((e) => {
          throw e;
        })
        .finally(() => {
          setSp(false);
        });
    },
    [sp, setSp]
  );

  const steps = React.useMemo(() => {
    return [
      {
        title: "输入调度员基本信息",
        icon: <FormOutlined />,
        description: "线下确认调度员的基本信息，为调度员分配登录帐号和密码",
      },
      {
        title: "绑定微信帐号",
        icon: <WechatOutlined />,
        description: "绑定微信帐号，确保调度员登录过小程序",
      },
    ];
  }, []);

  const components = React.useMemo(() => {
    return [BasicForm, Wechat];
  }, []);

  const content = React.useCallback(() => {
    const C = components[current];
    return (
      <C
        Next={nextStep}
        Current={current}
        IsFinnal={current === components.length - 1}
        InitialBasic={basic}
        OnCommit={setBasic}
        RegisterDispatcher={commitDispatch}
      />
    );
  }, [current, nextStep, basic, setBasic, commitDispatch, components])();

  return (
    <Spin spinning={sp}>
      <PageHeader
        title="添加调度员"
        ghost={false}
        extra={
          <Button
            type="primary"
            icon={<PoweroffOutlined />}
            size="large"
            onClick={() => props.history.goBack()}
          >
            返回
          </Button>
        }
      />
      <Divider />
      <div className="add-dispatcher-container">
        <div className="add-dispatcher-step-current">
          <Steps current={current}>
            {steps.map(
              (step: {
                title: string;
                icon: React.ReactNode;
                description: string;
              }) => {
                return (
                  <Steps.Step
                    title={step.title}
                    icon={step.icon}
                    description={step.description}
                  />
                );
              }
            )}
          </Steps>
        </div>
        <div className="add-dispatcher-step-content">{content}</div>
      </div>
    </Spin>
  );
};

export default withRouter(AddDispatcher);
