import React from "react";
import { Spin, message, PageHeader, Row, Col, Divider, Table } from "antd";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ServerConfig } from "../../../../constants/configs";
import { setConfigs } from "../../../../actions/configs";
import { getConfigs as ReqConfigs } from "../../../../utils/api";
import { ServerRes } from "../../../../utils/request";
import dayjs from "dayjs";

const s2p = ({ Configs }: { Configs: ServerConfig }) => ({
  Configs,
});

const d2p = (dispatch: any) => ({
  setConfigs: (configs: ServerConfig) => dispatch(setConfigs(configs)),
});

type IProps = ReturnType<typeof s2p> &
  ReturnType<typeof d2p> &
  RouteComponentProps;

const ChargeConfig: React.FC<IProps> = (props: IProps) => {
  const [sp, setSp] = React.useState(false);

  const getConfigs = React.useCallback(() => {
    setSp(true);

    ReqConfigs()
      .then((res) => {
        return res.json();
      })
      .then((res: ServerRes<ServerConfig>) => {
        if (res.Error === 0) {
          props.setConfigs(res.Result);
        }
      })
      .catch((e) => {
        message.error(`获取配置信息失败：${e}`);
      })
      .finally(() => {
        setSp(false);
      });
  }, [setSp, props]);

  React.useEffect(() => {
    document.title = "收费配置";
    if (props.Configs === null) {
      getConfigs();
    }
  }, [props, getConfigs]);

  const periodColumns = React.useMemo(() => {
    return [
      {
        dataIndex: "type",
        title: "时段",
        render: () => "白天",
      },
      {
        dataIndex: "from",
        title: "开始时间",
        render: (time: number) => {
          let t =
            dayjs(dayjs().format("YYYY-MM-DD")).toDate().getTime() / 1000 +
            time;
          return dayjs(t * 1000).format("HH:mm:ss");
        },
      },
      {
        dataIndex: "to",
        title: "结束时间",
        render: (time: number) => {
          let t =
            dayjs(dayjs().format("YYYY-MM-DD")).toDate().getTime() / 1000 +
            time;
          return dayjs(t * 1000).format("HH:mm:ss");
        },
      },
      {
        title: "修改",
        render: () => <span>修改</span>,
      },
    ];
  }, []);

  const startingColumns = React.useMemo(() => {
    return [
      {
        dataIndex: "model",
        title: "车型",
      },
      {
        dataIndex: "daytime",
        title: "白天",
        render: (fee: number) => <span>￥{fee / 100}</span>,
      },
      {
        dataIndex: "night",
        title: "夜间",
        render: (fee: number) => <span>￥{fee / 100}</span>,
      },
      {
        title: "修改",
        render: () => <span>修改</span>,
      },
    ];
  }, []);

  const models = React.useMemo(() => {
    if (props.Configs === null) {
      return [];
    }

    let m = [];

    props.Configs.starting.map(function (s) {
      m[s.id] = s.model;
      return null;
    });

    return m;
  }, [props.Configs]);

  const deliverColumns = React.useMemo(() => {
    return [
      {
        dataIndex: "model",
        title: "车型",
        render: (m: number) => models[m],
      },
      {
        dataIndex: "minimal",
        title: "大于(包含)",
        render: (m: number) => <span>{m / 1000} 公里</span>,
      },
      {
        dataIndex: "maximum",
        title: "小于(不包含)",
        render: (m: number) => <span>{m / 1000} 公里</span>,
      },
      {
        dataIndex: "daytime",
        title: "白天(每公里)",
        render: (m: number) => <span>￥{m / 100}</span>,
      },
      {
        dataIndex: "night",
        title: "夜间(每公里)",
        render: (m: number) => <span>￥{m / 100}</span>,
      },
    ];
  }, [models]);

  const rescueColumns = React.useMemo(() => {
    return [
      {
        dataIndex: "wheel",
        title: "辅助轮(元/个)",
        render: (m: number) => <span>￥{m / 100}</span>,
      },
      {
        dataIndex: "normal",
        title: "现场救援[一般难度](元/次)",
        render: (m: number) => <span>￥{m / 100}</span>,
      },
      {
        dataIndex: "hard",
        title: "现场救援[较大难度](元/次)",
        render: (m: number) => <span>￥{m / 100}</span>,
      },
      {
        dataIndex: "basement",
        title: "地库救援(元/次)",
        render: (m: number) => <span>￥{m / 100}</span>,
      },
    ];
  }, []);

  return (
    <div>
      <PageHeader title="服务配置" ghost={false} />
      <Divider />
      <Spin spinning={sp} tip="正在读取配置">
        <Row gutter={24}>
          <Col span={12}>
            <Table
              rowKey="id"
              title={() => <h2>时段配置</h2>}
              columns={periodColumns}
              dataSource={props.Configs ? [props.Configs.period] : []}
              pagination={false}
            />
          </Col>
          <Col span={12}>
            <Table
              rowKey="id"
              title={() => <h2>服务项配置</h2>}
              columns={rescueColumns}
              dataSource={props.Configs ? [props.Configs.rescue] : []}
              pagination={false}
            />
          </Col>
          <Divider />
          <Col span={12}>
            <Table
              rowKey="id"
              title={() => <h2>起步价配置</h2>}
              columns={startingColumns}
              dataSource={props.Configs ? props.Configs.starting : []}
              pagination={false}
            />
          </Col>
          <Col span={12}>
            <Table
              rowKey="id"
              title={() => <h2>距离收费配置</h2>}
              columns={deliverColumns}
              dataSource={props.Configs ? props.Configs.delivers : []}
              pagination={false}
            />
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default connect(s2p, d2p)(withRouter(ChargeConfig));
