import { PageData } from "../constants/globa";
import { IDriver, PAGE_DRIVER, PATCH_DRIVER } from "../constants/driver";

const initialState: PageData<IDriver> = {
  page: 0,
  size: 20,
  total: 0,
  payload: [],
  statistic: {
    today: 0,
    history: 0,
  },
};

export const Driver = (state = initialState, action: any) => {
  switch (action.type) {
    case PAGE_DRIVER: {
      return action.page;
    }
    case PATCH_DRIVER: {
      const payload = [...state.payload];
      payload.forEach((p, i) => {
        if (p.openid === action.driver.openid) {
          payload[i] = action.driver;
        }
      });
      return { ...state, ...{ payload } };
    }
    default:
      return state;
  }
};
