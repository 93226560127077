import RSA, { Options } from "node-rsa";
import pk from "./pub";

const rsa = new RSA(pk, "pkcs1-public-pem");
const opt: Options = {
  environment: "browser",
  encryptionScheme: "pkcs1_oaep",
  signingScheme: "pkcs1-sha1",
};

rsa.setOptions(opt);

const encrypt = (text: string) => {
  let cipher = rsa.encrypt(text, "buffer");
  return cipher.toString("base64");
};

export { encrypt };
