// 调度系统API

import { RT } from "./request";

export const host = "http://127.0.0.1:9191";

const jsonHeaders = new Headers();
jsonHeaders.append("content-type", "application/json");

export const queryDispatcherPage = (page: number, size: number) => {
  let url = `${host}/api/v1/super/page/dispatcher?page=${page}&size=${size}`;

  const request = new Request(url, {
    method: "GET",
  });

  return RT(request, 5000);
};

// 注册调度员
export const registerDispatcher = (args: {
  name?: string;
  contact?: string;
  account?: string;
  password?: string;
  vPassword?: string;
}) => {
  let url = `${host}/api/v1/super/register/dispatcher`;

  const data = JSON.stringify(args);
  const request = new Request(url, {
    method: "POST",
    headers: jsonHeaders,
    body: data,
    mode: "cors",
  });

  return RT(request, 5000);
};

export const getDispatcherQR = (did: string) => {
  return `${host}/statics/qrcode/dispatcher_${did}.png`;
};

// 注册合作伙伴
export const registerPartner = (args: {
  name: string;
  address: string;
  contact_name: string;
  contact_number: string;
}) => {
  let url = `${host}/api/v1/super/register/partner`;
  const data = JSON.stringify(args);
  const request = new Request(url, {
    method: "POST",
    headers: jsonHeaders,
    body: data,
    mode: "cors",
  });

  return RT(request, 5000);
};

// 分页获取合作伙伴
export const pagePartner = (page: number = 1, size: number = 20) => {
  let url = `${host}/api/v1/super/page/partner?page=${page}&size=${size}`;
  const request = new Request(url);

  return RT(request, 5000);
};

// 分页获取查勘员
export const pageServeyor = (
  page: number = 1,
  size: number = 20,
  partner: string = ""
) => {
  let url = `${host}/api/v1/super/page/serveyor?page=${page}&size=${size}&partner=${partner}`;
  const request = new Request(url);

  return RT(request, 5000);
};

// 获取查勘员二维码
export const getServeyorQR = (did: string) => {
  return `${host}/statics/qrcode/serveyor_${did}.png`;
};
