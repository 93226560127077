import React from 'react';
import { Button, PageHeader, Table, Tag, message, Divider, Modal, Form, Input, Checkbox, Spin, Popconfirm } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { ServerConfig, INotificationsCfg } from '../../../../constants/configs';
import { setConfigs } from '../../../../actions/configs';
import { getConfigs as ReqConfigs, addNotification, removeNotification } from '../../../../utils/api';
import { ServerRes } from '../../../../utils/request';
import { Store } from 'antd/lib/form/interface';

type IProps = ReturnType<typeof s2p> & ReturnType<typeof d2p> & RouteComponentProps

const s2p = ({ Configs }: { Configs: ServerConfig }) => ({
    Configs
})

const d2p = (dispatch: any) => ({
    setConfigs: (configs: ServerConfig) => dispatch(setConfigs(configs)),
})

const NotificationConfig: React.FC<IProps> = (props: IProps) => {

    const [sp, setSp] = React.useState(false);
    const [req, setReq] = React.useState(false);
    const [tagsToAdd, setTagsToAdd] = React.useState<Array<string>>([]);
    const [addModel, setAddModel] = React.useState(false);

    const getConfigs = React.useCallback(() => {
        setSp(true);

        ReqConfigs().then(res => {
            return res.json();
        }).then((res: ServerRes<ServerConfig>) => {
            if (res.Error === 0) {
                props.setConfigs(res.Result);
            }
        }).catch((e) => {
            message.error(`获取配置信息失败：${e}`);
        }).finally(() => {
            setSp(false);
        });

    }, [setSp, props]);

    React.useEffect(() => {
        document.title = "收费配置";
        if (props.Configs === null) {
            getConfigs();
        }
    }, [getConfigs, props.Configs]);

    const tags = React.useMemo(() => {
        return {
            'service': {
                title: '请求服务',
                color: 'green',
            },
            'payment': {
                title: '支付',
                color: 'blue',
            },
            'credit': {
                title: '挂帐',
                color: 'red',
            },
            'instant': {
                title: '即时支付',
                color: 'purple'
            },
        }
    }, []);

    const columns = React.useMemo(() => {
        return [{
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
        }, {
            title: '手机号码',
            dataIndex: 'phone',
            key: 'phone',
        }, {
            title: '通知项',
            dataIndex: 'tags',
            key: 'tags',
            render: (events: string[]) => events.map((tag, i) => <Tag color={tags[tag].color} key={i}>{tags[tag].title}</Tag>)
        }, {
            title: '操作',
            render: (item: INotificationsCfg) => <Popconfirm title="确定删除？" okText="删除" cancelText="取消" onConfirm={() => { handleRemoveItem(item) }}><a href="">删除</a></Popconfirm>
        }];
    }, [props])

    const formLayout = React.useMemo(() => {
        return {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        };
    }, [])

    // 添加
    const [form] = Form.useForm()
    const handleAddSubmit = React.useCallback((values: Store) => {
        const data = { ...values, tags: tagsToAdd }
        if (data.tags.length === 0) {
            message.warning("请选择至少一个通知项");
            return;
        }

        setReq(true);
        addNotification({ ...data } as ({ phone: string, name: string, tags: string[] })).then((res) => {
            return res.json();
        }).then((res: ServerRes<INotificationsCfg>) => {
            if (res.Error === 0) {
                const { notifications } = props.Configs;
                notifications.push(res.Result);
                const nextConfig = { ...props.Configs, ...{ notifications } };
                form.resetFields();
                setAddModel(false);
                message.success("添加成功")
                window.location.reload();
            } else {
                message.error(`添加通知人失败：${res.Desc}`);
            }
        }).catch((e) => {
            console.error(`Error: ${e}`);
            message.error(`添加通知人失败：${e}`);
        }).finally(() => {
            setReq(false);
        })
    }, [tagsToAdd, setAddModel, setReq, form, props]);

    // 删除
    const handleRemoveItem = React.useCallback((item: INotificationsCfg) => {
        if (props.Configs === null || props.Configs.notifications.length === 0) {
            message.warning(`删除失败:${props.Configs}`);
            return;
        }
        setReq(true)
        removeNotification({ id: item.id }).then((res) => {
            return res.json();
        }).then((res: ServerRes<null>) => {
            if (res.Error === 0) {
                const notifications = props.Configs.notifications.filter((nt) => nt.id !== item.id);
                const nextConfig = { ...props.Configs, ...{ notifications } };
                props.setConfigs(nextConfig);
                message.success("删除成功")
            } else {
                message.warning(`删除失败：${res.Desc}`);
            }
        }).catch((e) => {
            message.warning(`删除失败：${e}`);
        }).finally(() => {
            setReq(false)
        })
    }, [props, setReq]);

    // 添加的标签
    const tagOptions = [
        { label: '请求服务', value: 'service' },
        { label: '支付', value: 'payment' },
        { label: '挂帐', value: 'credit' },
        { label: '即时支付', value: 'instant' }
    ];

    const handleAddTagsChange = React.useCallback((values: any) => {
        setTagsToAdd(values);
    }, [setTagsToAdd]);

    return <div>
        <PageHeader title="通知配置" subTitle="客户有新的操作时通过短信通知一下人员" ghost={false} extra={[<Button key={1} onClick={() => setAddModel(true)} type="primary" icon={<PlusOutlined />}>添加通知人</Button>]} />
        <Divider />
        <div className='config-body'>
            <Table rowKey="id" loading={sp} columns={columns} dataSource={props.Configs ? props.Configs.notifications : []} />
            <Modal visible={addModel} title="添加通知人员" footer={null} onCancel={() => setAddModel(false)}>
                <Spin tip="正在提交" spinning={req}>
                    <Form {...formLayout} onFinish={handleAddSubmit} form={form}>
                        <Form.Item label="姓名" name="name" rules={[{ required: true, message: '请输入通知人姓名' }, { pattern: /^[\s\S]*.*[^\s][\s\S]*$/, message: '请输入通知人姓名' }]}>
                            <Input name="name" />
                        </Form.Item>
                        <Form.Item label="手机号码" name="phone" rules={[{ required: true, message: '请输入通知人手机号码' }, { pattern: /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/, message: '请输入正确的手机号码' }]}>
                            <Input name="phone" />
                        </Form.Item>
                        <Form.Item label="通知项" name="tags">
                            <Checkbox.Group options={tagOptions} onChange={handleAddTagsChange} />
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
                            <Button type="primary" htmlType="submit">添加</Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </div>
    </div>
}


export default connect(s2p, d2p)(withRouter(NotificationConfig));
