import { PageData, PageAction } from "../constants/globa";
import { IPartner, PAGE_PARTNER } from "../constants/partner";

const initialState: PageData<IPartner> = {
  page: 0,
  size: 20,
  total: 0,
  payload: [],
  statistic: {
    today: 0,
    history: 0,
  },
};

export const Partner = (state = initialState, action: PageAction<IPartner>) => {
  switch (action.type) {
    case PAGE_PARTNER:
      return action.page;
    default:
      return state;
  }
};
