export type login_args = {
    username: string;
    password: string;
};

export type IUser = {
    Id: number;
    Username: string;
    Password: string;
    Realname: string;
    Phone: string;
}

export const SET_USER = 'SET_USER';
