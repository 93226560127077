import {
  CheckCircleOutlined,
  CheckSquareOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  SyncOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Checkbox,
  Divider,
  Input,
  message,
  PageHeader,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React from "react";
import { connect } from "react-redux";
import { pageDriver, patchDriver } from "../../../../actions/driver";
import { IDriver } from "../../../../constants/driver";
import { PageData } from "../../../../constants/globa";
import { confirmDriver, queryDrivers } from "../../../../utils/api";
import { ServerRes } from "../../../../utils/request";

const s2p = ({ Driver }: { Driver: PageData<IDriver> }) => ({ Driver });

const d2p = (dispatch: any) => ({
  PageDriver: (page: PageData<IDriver>) => dispatch(pageDriver(page)),
  PatchDriver: (driver: IDriver) => dispatch(patchDriver(driver)),
});

type IProps = ReturnType<typeof s2p> & ReturnType<typeof d2p>;

const Drivers: React.FC<IProps> = (props: IProps) => {
  // 保存数据是否初始化的状态
  const [f, sf] = React.useState<boolean>(false);

  // Spining, SetSping
  const [sp, ssp] = React.useState<boolean>(false);

  // 保存查找状态
  const [filter, setFilter] = React.useState<string>("");

  // 封装的获取数据函数
  const queryData = React.useCallback(
    async (page: number = 1, size: number = 20, params = "") => {
      ssp(true);
      try {
        let _res = await queryDrivers(page, size, params);
        let res: ServerRes<PageData<IDriver>> = await _res.json();
        if (res.Error === 0) {
          props.PageDriver(res.Result);
          sf(true);
        } else {
          throw new Error("获取数据失败");
        }
      } catch (e) {
        message.error(`获取数据失败：${e.message}`);
      } finally {
        ssp(false);
      }
    },
    [props, ssp, sf]
  );

  // 保存查找翻译字段对照
  const searchTrans = {
    name: "姓名",
    license: "驾照号码",
    phone: "联系电话",
    company: "所属公司",
  };

  // 保存审核状态对照
  const status = [
    {
      value: 0,
      text: "待审核",
    },
    {
      value: 1,
      text: "已审核",
    },
    {
      value: -1,
      text: "未通过",
    },
  ];

  // 审核状态过滤
  const statusFilters = React.useState<number[]>([]);

  let inputNode = React.useRef();

  // 审核通过
  const positiveConfirm = React.useCallback(
    (driver: IDriver) => {
      ssp(true);
      confirmDriver({ openid: driver.openid, confirm: 1 })
        .then((res) => {
          return res.json();
        })
        .then((res: ServerRes<IDriver>) => {
          if (res.Error === 0) {
            message.success("操作成功");
            props.PatchDriver(res.Result);
          } else {
            message.error(`通过失败：${res.Desc}`);
          }
        })
        .catch((e) => {
          message.error(`通过失败：${e.message}，请联系管理员`);
        })
        .finally(() => {
          ssp(false);
        });
    },
    [ssp, props]
  );

  // 审核不通过
  const negativeConfirm = React.useCallback(
    (driver: IDriver) => {
      ssp(true);
      confirmDriver({ openid: driver.openid, confirm: -1 })
        .then((res) => {
          return res.json();
        })
        .then((res: ServerRes<IDriver>) => {
          if (res.Error === 0) {
            message.success("操作成功");
            props.PatchDriver(res.Result);
          } else {
            message.error(`操作失败：${res.Desc}`);
          }
        })
        .catch((e) => {
          message.error(`操作失败：${e.message}，请联系管理员`);
        })
        .finally(() => {
          ssp(false);
        });
    },
    [ssp, props]
  );

  // 重置驾驶员确认状态，当驾驶员离职时使用
  const resetConfirm = React.useCallback(
    (driver: IDriver) => {
      ssp(true);
      confirmDriver({ openid: driver.openid, confirm: 0 })
        .then((res) => {
          return res.json();
        })
        .then((res: ServerRes<IDriver>) => {
          if (res.Error === 0) {
            message.success("操作成功");
            props.PatchDriver(res.Result);
          } else {
            message.error(`操作失败：${res.Desc}`);
          }
        })
        .catch((e) => {
          message.error(`操作失败：${e.message}，请联系管理员`);
        })
        .finally(() => {
          ssp(false);
        });
    },
    [ssp, props]
  );

  // 确认筛选
  const handleSearch = React.useCallback(
    (selectedKeys: string[], _: Function, dataIndex: string) => {
      /* confirm(); */
      setFilter("");
      const key = dataIndex;
      const value = selectedKeys[0];
      if (value === undefined || value === null || value === "") {
        return;
      }
      let f = window.btoa(encodeURIComponent(`${key}=${value}`));
      queryData(1, props.Driver.size, f).then(() => {
        setFilter(f);
      });
    },
    [setFilter, queryData, props.Driver.size]
  );

  // 重置筛选
  const handleReset = React.useCallback(
    (clearFilters: Function) => {
      queryData(1, props.Driver.size, "").then(() => {
        setFilter("");
        clearFilters();
      });
    },
    [props.Driver.size, queryData]
  );

  // 通用的过滤函数
  const getColumnSearchProps = React.useCallback(
    (dataIndex: any) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={inputNode}
            placeholder={`按${searchTrans[dataIndex]}查找`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              查找
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              重置
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
          setTimeout(() => {});
        }
      },
    }),
    [handleReset, handleSearch, searchTrans]
  );

  // 通过状态查询
  const filterCofirms = React.useCallback(
    (action: "submit" | "reset", status: number[]) => {
      if (action === "submit") {
      }
      if (action === "reset") {
      }

      const params = window.btoa(`confirm=${status.join(",")}`);
      setFilter(params);

      queryData(1, props.Driver.size, params);
    },
    [setFilter, props.Driver.size, queryData]
  );

  // 表格的列定义
  const columes = React.useMemo(() => {
    return [
      {
        dataIndex: "avatar",
        title: "微信头像",
        render: (avatar: string) => (
          <Avatar shape="square" size={48} src={avatar} />
        ),
      },
      {
        dataIndex: "nickname",
        title: "微信昵称",
      },
      {
        dataIndex: "name",
        title: "姓名",
        ...getColumnSearchProps("name"),
      },
      {
        dataIndex: "gender",
        title: "性别",
        render: (g: number) => ["男", "女"][g],
      },
      {
        dataIndex: "license",
        title: "驾照号码",
        ...getColumnSearchProps("license"),
      },
      {
        dataIndex: "phone",
        title: "联系电话",
        ...getColumnSearchProps("phone"),
      },
      {
        title: "区域",
        dataIndex: "region_name",
      },
      {
        dataIndex: "company",
        title: "所属公司",
        ...getColumnSearchProps("company"),
      },
      {
        dataIndex: "confirm",
        title: "审核状态",
        filterDropdown: (p: any) => (
          <div style={{ padding: 8 }}>
            {status.map((f) => (
              <div style={{ padding: "8px 0" }}>
                <Checkbox
                  key={f.value}
                  checked={statusFilters[0].includes(f.value)}
                  onChange={(e: any) => {
                    const { checked } = e.target;
                    let next = new Set(statusFilters[0]);
                    if (checked && !statusFilters[0].includes(f.value)) {
                      statusFilters[0].push(f.value);
                      next.add(f.value);
                    }
                    if (!checked) {
                      statusFilters[0].forEach((s: number) => {
                        if (s === f.value) {
                          next.delete(s);
                        }
                      });
                    }
                    let s = [];
                    next.forEach((v: number) => {
                      s.push(v);
                    });
                    statusFilters[1](s);
                  }}
                >
                  {f.text}
                </Checkbox>
              </div>
            ))}
            <div style={{ margin: "4px 0" }}>
              <Button
                type="primary"
                onClick={() => filterCofirms("submit", statusFilters[0])}
              >
                筛选
              </Button>
              <Divider type="vertical" />
              <Button
                type="default"
                onClick={() => {
                  filterCofirms("reset", []);
                  statusFilters[1]([]);
                }}
              >
                重置
              </Button>
            </div>
          </div>
        ),
        render: (c: number) => {
          const text = ["未通过", "待审核", "已通过"][c + 1];
          if (c === -1) {
            return <Tag icon={<CloseCircleOutlined />}>{text}</Tag>;
          }
          if (c === 0) {
            return (
              <Tag color="processing" icon={<SyncOutlined spin={true} />}>
                {text}
              </Tag>
            );
          }

          if (c === 1) {
            return (
              <Tag color="success" icon={<CheckCircleOutlined />}>
                {text}
              </Tag>
            );
          }

          return <Button>{text}</Button>;
        },
      },
      {
        title: "审核操作",
        render: (row: IDriver) => {
          if (row.confirm === -1) {
            return (
              <>
                <Button
                  type="primary"
                  icon={<CheckSquareOutlined />}
                  size="large"
                  ghost={true}
                  onClick={() => positiveConfirm(row)}
                >
                  重新通过
                </Button>
              </>
            );
          }
          if (row.confirm === 0) {
            return (
              <>
                <Button
                  type="primary"
                  icon={<CheckSquareOutlined />}
                  size="large"
                  ghost={true}
                  onClick={() => positiveConfirm(row)}
                >
                  通过
                </Button>
                <Divider type="vertical" />
                <Button
                  icon={<WarningOutlined />}
                  size="large"
                  ghost={true}
                  onClick={() => negativeConfirm(row)}
                >
                  不通过
                </Button>
              </>
            );
          }
          return (
            <>
              <Tooltip title="驾驶员离职时请重置驾驶员身份" placement="bottom">
                <Popconfirm
                  onCancel={() => {}}
                  title="确认重置状态？重置状态后将无法填写小程序服务信息！"
                  onConfirm={() => resetConfirm(row)}
                >
                  <Button icon={<WarningOutlined />} size="large">
                    重置状态
                  </Button>
                </Popconfirm>
              </Tooltip>
            </>
          );
        },
      },
    ];
  }, [
    statusFilters,
    filterCofirms,
    getColumnSearchProps,
    negativeConfirm,
    positiveConfirm,
    resetConfirm,
    status,
  ]);

  // 初始化数据的入口
  React.useEffect(() => {
    if (!f && props.Driver.page === 0) {
      queryData(props.Driver.page, props.Driver.size);
      sf(true);
    }
  }, [f, sf, queryData, props]); // 添加statusFilters[0]，变化的时候重新渲染状态过滤器(confirm字段)

  return (
    <div>
      <PageHeader
        title="驾驶员列表"
        ghost={false}
        extra={[
          <Button
            key="refresh"
            size="large"
            type="primary"
            icon={<SyncOutlined spin={sp} />}
            onClick={() => queryData(1, 20)}
          >
            刷新
          </Button>,
        ]}
      />
      <Table
        columns={columes}
        dataSource={props.Driver.payload}
        loading={sp}
        rowKey="openid"
        pagination={{
          pageSize: props.Driver.size,
          current: props.Driver.page,
          total: props.Driver.total,
          onChange: (page: number, pageSize: number) => {
            queryData(page, pageSize, filter);
          },
          onShowSizeChange: (page: number, pageSize: number) => {
            queryData(page, pageSize, filter);
          },
          showTotal: (total: number, range: [number, number]) => (
            <span>
              共{total}条记录，当前第{range[0]} - {range[1]}条
            </span>
          ),
        }}
      />
    </div>
  );
};

export default connect(s2p, d2p)(Drivers);
