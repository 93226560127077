import { PageData, PageAction } from "../constants/globa";
import { ICredit, PAGE_CREDIT } from "../constants/credit";

const initialState: PageData<ICredit> = {
    page: 0,
    size: 20,
    total: 0,
    payload: [],
    statistic: {
        today: 0,
        history: 0,
    },
}

export const Credit = (state = initialState, action: PageAction<ICredit>) => {
    switch (action.type) {
        case PAGE_CREDIT:
            return action.page;
        default:
            return state;
    }
}
