import { IUser, SET_USER } from "../constants/login"


function getUserFromStorage(): IUser | null {
    let item = window.localStorage.getItem('user');
    if (item == null) {
        return null;
    }
    try {
        let user: IUser = JSON.parse(item)
        return user;
    } catch (e) {
        return null;
    }
}

const initialState = getUserFromStorage()

type Action = {
    type: string,
    user: IUser
}

export const User = (state: IUser | null = initialState, action: Action) => {
    switch (action.type) {
        case SET_USER:
            return action.user;
        default: return state;
    }
};
