import {
  ArrowLeftOutlined,
  PayCircleOutlined,
  DisconnectOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Descriptions,
  Divider,
  PageHeader,
  Spin,
  Row,
  Col,
  Tag,
  message,
  Modal,
  notification,
  Form,
  Input,
} from "antd";
import dayjs from "dayjs";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  IBasement,
  ICrane,
  ILocation,
  IServiceItems,
  IVehicleTransport,
  IWheel,
  Service,
  STATUS,
  ISceneRescue,
  IHighwaySurcharge,
  IPickUp,
} from "../../../../constants/service";
import {
  getServiceItems,
  host,
  getServiceDetails,
  refund,
  deBuyingOnCredit,
} from "../../../../utils/api";
import { encrypt } from "../../../../utils/rsa";
import { ServerRes } from "../../../../utils/request";
import "./styles.css";
import { IPayment } from "../../../../constants/payment";
import { patchService } from "../../../../actions/service";

const refunder_key = (() => "tester")();

const s2p = () => ({});

const d2p = (dispatch: any) => ({
  patchService: (service: Service) => dispatch(patchService(service)),
});

type IProps = RouteComponentProps<{ sid: string }> &
  ReturnType<typeof d2p> &
  ReturnType<typeof s2p>;

const ServiceDetails: React.FC<IProps> = (props: IProps) => {
  const [basic, setBasic] = React.useState<Service>();
  const [items, setItems] = React.useState<IServiceItems>();
  const [sp, setSp] = React.useState(false);
  const [refundSp, setRefundSp] = React.useState(false);
  const [keyModal, setKeyModal] = React.useState(false);

  const getItems = React.useCallback(() => {
    getServiceItems({ sid: props.match.params.sid })
      .then((res: Response) => {
        return res.json();
      })
      .then((res: ServerRes<IServiceItems>) => {
        if (res.Error === 0) {
          setItems(res.Result);
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setSp(false);
      });
  }, [props, setItems, setSp]);

  const getBasic = React.useCallback((sid: string) => {
    getServiceDetails(sid)
      .then((res) => {
        return res.json();
      })
      .then((res: ServerRes<{ service: Service }>) => {
        if (res.Error === 0) {
          setBasic(res.Result.service);
        }
      })
      .catch((e) => {
        console.error(e);
        message.error(`无法读取服务详情:${e}`);
      });
  }, []);

  // 请求退款
  const doRefund = React.useCallback(
    async (r_key: string) => {
      if (basic.sid === "" || basic.status !== 2 || r_key == "") {
        return;
      }
      setRefundSp(true);
      let raw = JSON.stringify({ key: refunder_key, raw: r_key });
      const cipher = encrypt(raw);

      try {
        setKeyModal(false);
        let res = await refund(basic.sid, cipher);
        let json: ServerRes<IPayment> = await res.json();
        if (json.Error === 0) {
          notification.success({
            message: "退款成功",
            description: "退款成功，服务费已返还给支付人！",
          });
          basic.status = 4;
          setBasic(basic);
          props.patchService(basic);
        } else {
          throw new Error("退款失败");
        }
      } catch (e) {
        notification.error({
          message: "退款失败",
          description:
            "退款失败，请确认退款密码是否正确，如果多次失败，请联系管理员！",
        });
        setKeyModal(false);
      } finally {
        setRefundSp(false);
      }
    },
    [basic, setBasic, props, refund]
  );

  // 处理退款
  const handleRefundClick = React.useCallback(() => {
    Modal.confirm({
      title: "确认退款？",
      icon: <ExclamationCircleOutlined />,
      content: "确认退款，资金将返还给客户！",
      okText: "确认",
      okType: "danger",
      cancelText: "取消",
      onOk() {
        setKeyModal(true);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }, [setKeyModal]);

  // 请求撤销挂帐
  const doDeBuyingOnCredit = React.useCallback(async () => {
    if (basic.sid === "" || basic.status !== 3) {
      return;
    }
    setRefundSp(true);

    try {
      let res = await deBuyingOnCredit(basic.sid, basic.id);
      let json: ServerRes<IPayment> = await res.json();
      if (json.Error === 0) {
        notification.success({
          message: "撤销挂帐成功",
          description: "撤销挂帐成功，用户可以在线付款！",
        });
        basic.status = 1;
        setBasic(basic);
        props.patchService(basic);
      } else {
        throw new Error("撤销挂帐失败");
      }
    } catch (e) {
      notification.error({
        message: "撤销挂帐失败",
        description: "撤销挂帐失败，请稍后再试，如果多次失败，请联系管理员！",
      });
    } finally {
      setRefundSp(false);
    }
  }, [basic, setBasic, props]);

  // 处理撤销挂帐
  const handleDeBuyingOnCreditClick = React.useCallback(() => {
    Modal.confirm({
      title: "确认撤销挂帐？",
      icon: <ExclamationCircleOutlined />,
      content: "确认撤销挂帐吗？撤销后用户可以直接支付！",
      okText: "确认",
      okType: "danger",
      cancelText: "取消",
      onOk() {
        doDeBuyingOnCredit();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }, [doDeBuyingOnCredit]);

  React.useEffect(() => {
    document.title = "服务详情";
    const sid = props.match.params.sid;
    if (basic == null) {
      const item = window.localStorage.getItem(`details.${sid}`);
      if (item != null) {
        try {
          let service = JSON.parse(item);
          setBasic(service);
        } catch (e) {
          console.error(e);
          getBasic(sid);
        }
      } else {
        getBasic(sid);
      }
    }
    if (items == null && !sp) {
      getItems();
    }
  }, []);

  const vts = items
    ? items.vehicleTransports.length > 0
      ? vtRender(items.vehicleTransports, items.locations)
      : null
    : null;
  const bs = items
    ? items.basement
      ? basementRender(items.basement)
      : null
    : null;
  const crane = items
    ? items.crane
      ? craneRender(items.crane, items.locations)
      : null
    : null;
  const wheel = items ? (items.wheel ? wheelRender(items.wheel) : null) : null;
  const sc = items
    ? items.sceneRescue
      ? scRender(items.sceneRescue)
      : null
    : null;

  const surcharge = items
    ? items.highwaySurcharge
      ? highwaySurchargeRender(items.highwaySurcharge)
      : null
    : null;

  const pickup = items
    ? items.pickup
      ? pickupRender(items.pickup, items.locations)
      : null
    : null;

  const isToday = dayjs(basic?.created_at * 1000).isSame(dayjs(), "day");

  let extra = [
    <Button type="primary">{STATUS[basic ? basic.status : 0]}</Button>,
  ];

  if (basic != null && basic.status === 2) {
    extra.push(
      <Button
        type="primary"
        danger={true}
        icon={<PayCircleOutlined />}
        onClick={handleRefundClick}
      >
        {"退款"}
      </Button>
    );
  }

  if (basic != null && basic.status === 3) {
    extra.push(
      <Button
        type="ghost"
        color="danger"
        icon={<DisconnectOutlined />}
        onClick={handleDeBuyingOnCreditClick}
      >
        {"取消挂帐"}
      </Button>
    );
  }

  const [keyForm] = Form.useForm();

  return (
    <Spin tip="正在退款，请稍后..." spinning={refundSp}>
      <PageHeader
        title="服务详情"
        ghost={false}
        backIcon={<ArrowLeftOutlined />}
        onBack={() => {
          props.history.goBack();
        }}
        extra={extra}
      >
        <div className="details-basic">
          <div className="details-image">
            <img
              src={basic ? `${host}/api/wx/service/image/${basic.sid}` : ""}
            />
          </div>
          <div className="details-service">
            <Descriptions title="基本信息">
              <Descriptions.Item label="客户姓名">
                {basic?.name} {basic?.gender}
              </Descriptions.Item>
              <Descriptions.Item label="联系电话">
                {basic?.phone}
              </Descriptions.Item>
              <Descriptions.Item label="车牌号码">
                {basic?.license}
              </Descriptions.Item>
              <Descriptions.Item label="车型">{basic?.model}</Descriptions.Item>
              <Descriptions.Item label="请求服务时间">
                {dayjs(basic ? basic.created_at * 1000 : 0).format(
                  "YYYY-MM-DD HH:mm:ss"
                )}{" "}
                {isToday ? <Tag color="#1890ff">今天</Tag> : null}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      </PageHeader>
      <div className="details-content">
        <Divider />
        <Spin spinning={sp}>
          <Row gutter={24}>
            {vts ? (
              <>
                {vts}
                <Divider />
              </>
            ) : null}
            {crane ? (
              <>
                {crane}
                <Divider />
              </>
            ) : null}
            {pickup ? (
              <>
                {pickup}
                <Divider />
              </>
            ) : null}
            {bs}
            {wheel}
            {sc}
            {surcharge}
          </Row>
        </Spin>
      </div>
      <Modal
        title="请输入退款密码"
        visible={keyModal}
        onCancel={() => {
          setKeyModal(false);
          keyForm.resetFields();
        }}
        footer={null}
      >
        <Form
          form={keyForm}
          onFinish={(values: { key: string }) => {
            doRefund(values.key);
          }}
        >
          <Form.Item
            name="key"
            label="退款密码"
            rules={[{ required: true, message: "请输入退款密码" }]}
          >
            <Input.Password name="key" placeholder="请在此处输入退款密码" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" block={true} htmlType="submit">
              确认退款
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Spin>
  );
};

const vtRender = (vts: IVehicleTransport[], locations: ILocation[]) => {
  let index = 1;
  return vts.map((vt) => {
    let starting = locations.find((location) => location.id === vt.starting);
    let destination = locations.find(
      (location) => location.id === vt.destination
    );
    return (
      <Col span={24}>
        <Descriptions
          title={`车辆拖运-${index++}`}
          key={vt.id}
          column={4}
          style={{ padding: "24px" }}
        >
          <Descriptions.Item label="费用">
            <Tag color="#1890ff">￥{vt.total_fee / 100}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label="是否收取起步价">
            {vt.starting_fee ? "是" : "否"}
          </Descriptions.Item>
          <Descriptions.Item label="起始地">
            {starting?.address} {starting?.name}
          </Descriptions.Item>
          <Descriptions.Item label="目的地">
            {destination?.address} {destination?.name}
          </Descriptions.Item>
          <Descriptions.Item label="距离">
            {vt.distance / 1000}公里
          </Descriptions.Item>
        </Descriptions>
      </Col>
    );
  });
};

const craneRender = (crane: ICrane, locations: ILocation[]) => {
  let starting = locations.find((location) => location.id === crane.starting);
  let destination = locations.find(
    (location) => location.id === crane.destination
  );

  return (
    <>
      <Col span={24} style={{ padding: "24px" }}>
        <Descriptions
          title="随车吊"
          bordered={false}
          column={4}
          layout="vertical"
        >
          <Descriptions.Item label="费用">
            <Tag color="#1890ff">
              ￥{crane.total_fee ? crane.total_fee / 100 : 0}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="起始地">
            {starting?.address} {starting?.name}
          </Descriptions.Item>
          <Descriptions.Item label="目的地">
            {destination?.address} {destination?.name}
          </Descriptions.Item>
          <Descriptions.Item label="距离">
            {crane.distance / 1000} 公里
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </>
  );
};

const pickupRender = (pickup: IPickUp, locations: ILocation[]) => {
  let starting = locations.find((location) => location.id === pickup.starting);
  let destination = locations.find(
    (location) => location.id === pickup.destination
  );

  return (
    <>
      <Col span={24} style={{ padding: "24px" }}>
        <Descriptions
          title="接送服务"
          bordered={false}
          column={4}
          layout="vertical"
        >
          <Descriptions.Item label="费用">
            <Tag color="#1890ff">
              ￥{pickup.total_fee ? pickup.total_fee / 100 : 0}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="起始地">
            {starting?.address} {starting?.name}
          </Descriptions.Item>
          <Descriptions.Item label="目的地">
            {destination?.address} {destination?.name}
          </Descriptions.Item>
          <Descriptions.Item label="距离">
            {pickup.distance / 1000} 公里
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </>
  );
};

const threeStyle = {
  padding: "36px",
};

const basementRender = (basement: IBasement) => (
  <>
    <Col span={6} style={threeStyle}>
      <Descriptions title="地库救援" layout="vertical">
        <Descriptions.Item label="费用">
          <Tag color="#1890ff">
            ￥{basement.total_fee ? basement.total_fee / 100 : 0}
          </Tag>
        </Descriptions.Item>
      </Descriptions>
    </Col>
  </>
);

const wheelRender = (wheel: IWheel) => (
  <>
    <Col span={6} style={threeStyle}>
      <Descriptions layout="vertical" title="辅助轮">
        <Descriptions.Item label="费用">
          <Tag color="#1890ff">￥{wheel.total_fee / 100}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="个数">{wheel.count}</Descriptions.Item>
      </Descriptions>
    </Col>
  </>
);

const scRender = (sc: ISceneRescue) => (
  <Col span={6} style={threeStyle}>
    <Descriptions layout="vertical" title="现场救援">
      <Descriptions.Item label="费用">
        <Tag color="#1890ff">￥{sc.total_fee / 100}</Tag>
      </Descriptions.Item>
      <Descriptions.Item label="难度">{sc.difficulty}</Descriptions.Item>
    </Descriptions>
  </Col>
);

const highwaySurchargeRender = (surcharge: IHighwaySurcharge) => (
  <>
    <Col span={6} style={threeStyle}>
      <Descriptions title="高速救援附加费" layout="vertical">
        <Descriptions.Item label="费用">
          <Tag color="#1890ff">
            ￥{surcharge ? surcharge.total_fee / 100 : 0}
          </Tag>
        </Descriptions.Item>
      </Descriptions>
    </Col>
  </>
);

export default connect(s2p, d2p)(withRouter(ServiceDetails));
