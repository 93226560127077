import React from "react";
import {
  PageHeader,
  Divider,
  Row,
  Col,
  Button,
  Spin,
  Form,
  Input,
  message,
} from "antd";
import { PoweroffOutlined } from "@ant-design/icons";

import { registerPartner } from "../../../../utils/dispatcher";
import { ServerRes } from "../../../../utils/request";
import { IPartner } from "../../../../constants/partner";
import { RouteComponentProps, withRouter } from "react-router-dom";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

type IFormProps = {
  name: string;
  address: string;
  contact_name: string;
  contact_number: string;
};

type IProps = RouteComponentProps;

const AddPartner = (props: IProps) => {
  const sp = React.useState(false);
  const [form] = Form.useForm();
  const initialValues = React.useMemo(() => {
    return {
      name: "",
      address: "",
      contact_name: "",
      contact_number: "",
    };
  }, []);

  const fields = React.useMemo(
    () => [
      {
        name: "name",
        label: "名称",
        rules: [{ required: true, message: "请输入合作伙伴名称" }],
      },
      {
        name: "address",
        label: "地址",
        rules: undefined,
      },
      {
        name: "contact_name",
        label: "联系人",
        rules: undefined,
      },
      {
        name: "contact_number",
        label: "联系电话",
        rules: undefined,
      },
    ],
    []
  );

  const onFormFinish = React.useCallback(
    async (values: IFormProps) => {
      if (values.address === "") {
        values.address = "未填写";
      }
      if (values.contact_name === "") {
        values.contact_name = "未填写";
      }

      if (values.contact_number === "") {
        values.contact_number = "未填写";
      }
      try {
        sp[1](true);
        let res = await registerPartner(values);
        let json: ServerRes<IPartner> = await res.json();
        if (json.Error === "C000000") {
          message.success("添加合作伙伴成功");
          form.resetFields();
        } else {
          throw new Error(`${json.Desc}`);
        }
      } catch (e) {
        message.error(`添加合作伙伴失败：${e}`);
      } finally {
        sp[1](false);
      }
    },
    [sp, form]
  );

  return (
    <Spin spinning={sp[0]}>
      <PageHeader
        ghost={false}
        title="添加合作伙伴"
        extra={
          <Button
            type="primary"
            size="large"
            icon={<PoweroffOutlined />}
            onClick={() => props.history.goBack()}
          >
            返回
          </Button>
        }
      />
      <Divider />
      <div className="add-partner-container">
        <Row gutter={14}>
          <Col span={12} offset={6}>
            <Form
              {...layout}
              name="add_partner_form"
              initialValues={initialValues}
              form={form}
              onFinish={onFormFinish}
            >
              {fields.map((f) => {
                return (
                  <Form.Item
                    key={f.name}
                    name={f.name}
                    label={f.label}
                    rules={f.rules}
                  >
                    <Input size="large" />
                  </Form.Item>
                );
              })}
              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit" size="large">
                  添加
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default withRouter(AddPartner);
