import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Divider, PageHeader, Table, message } from "antd";

import { pagePartner as queryPartnerPage } from "../../../../utils/dispatcher";
import { ServerRes } from "../../../../utils/request";

import { pagePartner } from "../../../../actions/partner";
import { IPartner } from "../../../../constants/partner";
import { PageData } from "../../../../constants/globa";

const s2p = ({ Partner }: { Partner: PageData<IPartner> }) => ({
  Partner,
});

const d2p = (d: any) => ({
  PagePartner: (page: PageData<IPartner>) => d(pagePartner(page)),
});

type IProps = RouteComponentProps &
  ReturnType<typeof s2p> &
  ReturnType<typeof d2p>;

const Partner: React.FC<IProps> = (props: IProps) => {
  // 是否已经初始化
  const initialed = React.useState(false);
  const tbSpin = React.useState(false);

  const colunmes = React.useMemo(() => {
    return [
      {
        title: "公司名称",
        dataIndex: "name",
      },
      {
        title: "公司地址",
        dataIndex: "address",
      },
      {
        title: "联系人",
        dataIndex: "contact_name",
      },
      {
        title: "联系电话",
        dataIndex: "contact_number",
      },
      {
        title: "操作",
        render: (row: IPartner) => {
          let toServeyor = `/serveyor/list?partner=${row.uid}`;
          return (
            <div>
              <Link to={toServeyor}>查勘员管理</Link>
              <Divider type="vertical" />
              监督员管理
            </div>
          ); // TODO
        },
      },
    ];
  }, []);

  const pageData = React.useCallback(
    (page: number, size: number) => {
      if (tbSpin[0]) {
        return;
      }
      tbSpin[1](true);
      queryPartnerPage(page, size)
        .then((res) => {
          return res.json();
        })
        .then((res: ServerRes<PageData<IPartner>>) => {
          if (res.Error == "C000000") {
            props.PagePartner(res.Result);
            message.success("获取成功");
          }
        })
        .catch((err: Error) => {
          console.log(err);
          message.error(`获取失败：${err.message}`);
        })
        .finally(() => {
          tbSpin[1](false);
        });
    },
    [tbSpin]
  );

  React.useEffect(() => {
    if (!initialed[0] && props.Partner.page == 0) {
      pageData(props.Partner.page + 1, props.Partner.size);
      initialed[1](true);
    }
  }, [initialed, props, pageData]);

  return (
    <div>
      <PageHeader
        title="合作伙伴列表"
        ghost={false}
        extra={
          <Button
            icon={<PlusCircleOutlined />}
            type="primary"
            size="large"
            onClick={() => props.history.push("/partner/add")}
          >
            添加合作伙伴
          </Button>
        }
      />
      <Divider />
      <Table
        rowKey="uid"
        loading={tbSpin[0]}
        columns={colunmes}
        dataSource={props.Partner.payload}
        pagination={{
          total: props.Partner.total,
          current: props.Partner.page,
          pageSize: props.Partner.size,
          showQuickJumper: true,
          showSizeChanger: true,
          onChange: pageData,
        }}
      />
    </div>
  );
};

export default withRouter(connect(s2p, d2p)(Partner));
