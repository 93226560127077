import React from "react";
import DataSet from "@antv/data-set";
import G2 from "@antv/g2";

import { sservice } from "../../../../utils/api";

const Service: React.FC = () => {
  React.useEffect(() => {
    sservice()
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        const data = json.Result.Items;

        const chart = new G2.Chart({
          container: "container",
          forceFit: true,
          height: 250,
          padding: [60, 20, 20, 60],
        });
        const ds = new DataSet();
        const dv = ds.createView().source(data);

        chart.scale("count", {
          alias: "服务次数",
        });

        chart.axis("date", {
          label: {
            textStyle: {
              fill: "#aaaaaa",
            },
          },
          tickLine: {
            length: 0,
          },
        });
        chart.axis("count", {
          label: {
            textStyle: {
              fill: "#333",
            },
          },
          title: {
            offset: 50,
          },
        });

        const view1 = chart.view();
        view1.source(data);
        view1.interval().position("date*count").opacity(1);

        const view2 = chart.view();
        view2.axis(false);
        view2.source(dv);
        view2
          .line()
          .position("date*count")
          .style({
            stroke: "#969696",
            lineDash: [3, 3],
          })
          .tooltip(true);
        view2.guide().text({
          content: "",
          position: ["min", "min"],
          style: {
            fill: "#8c8c8c",
            fontSize: 14,
            fontWeight: 300,
          },
          offsetY: -80,
        });

        chart.render();

        let t;
        window.addEventListener("resize", () => {
          t = setTimeout(() => {
            clearTimeout(t);
            chart.render();
          }, 200);
        });
      })
      .catch(() => {});
  }, []);

  return (
    <div>
      <div id="container" style={{ width: "100%", height: "250px" }} />
    </div>
  );
};

export default Service;
