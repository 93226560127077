import { IDispatcher, PAGE_DISPATCHER } from "../constants/dispatcher";
import { PageData, PageAction } from "../constants/globa";

const initialaState: PageData<IDispatcher> = {
  page: 0,
  size: 20,
  total: 0,
  payload: [],
  statistic: {
    today: 0,
    history: 0,
  },
};

export const Dispatcher = (
  state = initialaState,
  action: PageAction<IDispatcher>
) => {
  switch (action.type) {
    case PAGE_DISPATCHER:
      return action.page;
    default:
      return state;
  }
};
