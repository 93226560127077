export const PAGE_DRIVER = "PAGE_DRIVER";
export const PATCH_DRIVER = "PATCH_DRIVER";
export const CONFIRM_DRIVER = "CONFIRM_DRIVER";

export interface IDriver {
  id: number;
  openid: string;
  nickname: string;
  avatar: string;
  name: string;
  license: string;
  gender: number;
  region_code: string;
  region_name: string;
  company: string;
  confirm: number;
}
