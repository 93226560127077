export const PAGE_PARTNER = "PAGE_PARTNER";

export type IPartner = {
  id?: number;
  uid?: string;
  pid?: string;
  name: string;
  address: string;
  contact_name: string;
  contact_number: string;
};
