import { PageData, PageAction } from "../constants/globa";
import { IPayment, PAGE_PAYMENT } from "../constants/payment";

const initialState: PageData<IPayment> = {
    page: 0,
    size: 20,
    total: 0,
    payload: [],
    statistic: {
        today: 0,
        history: 0,
    },
}

export const Payment = (state = initialState, action: PageAction<IPayment>) => {
    switch (action.type) {
        case PAGE_PAYMENT:
            return action.page;
        default:
            return state;
    }
}
