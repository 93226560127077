import { PageData } from "../constants/globa";
import { Service, PAGE_SERVICE, PATCH_SERVICE } from "../constants/service";

export const pageService = (page: PageData<Service>) => ({
  type: PAGE_SERVICE,
  page,
});

export const patchService = (service: Service) => ({
  type: PATCH_SERVICE,
  service,
});
