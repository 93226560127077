import { PlusCircleFilled } from "@ant-design/icons";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Button,
  Divider,
  Modal,
  message,
  PageHeader,
  Table,
  Tooltip,
  Avatar,
} from "antd";
import * as React from "react";
import { connect } from "react-redux";
import { pageDispatcher } from "../../../../actions/dispatcher";
import { IDispatcher } from "../../../../constants/dispatcher";
import { PageData } from "../../../../constants/globa";
import {
  queryDispatcherPage,
  getDispatcherQR,
} from "../../../../utils/dispatcher";
import { ServerRes } from "../../../../utils/request";

const s2p = ({ Dispatcher }: { Dispatcher: PageData<IDispatcher> }) => ({
  Dispatcher,
});

const d2p = (d: any) => ({
  PageDispatcher: (page: PageData<IDispatcher>) => d(pageDispatcher(page)),
});

type IProps = ReturnType<typeof s2p> &
  ReturnType<typeof d2p> &
  RouteComponentProps;

const Dispatcher: React.FC<IProps> = (props: IProps) => {
  // 是否已经初始化
  const initialed = React.useState(false);
  const tbSpin = React.useState(false);
  const showQrImage = React.useCallback((dispatcher: IDispatcher) => {
    let modal = Modal.info({
      content: (
        <div>
          <Avatar
            size={240}
            src={getDispatcherQR(dispatcher.did)}
            style={{ borderRadius: 0 }}
          />
        </div>
      ),
      centered: true,
      okText: "关闭",
      title: "扫描二维码进行绑定",
      onOk: () => modal.destroy(),
    });
  }, []);

  const colunmes = React.useMemo(() => {
    return [
      {
        title: "微信头像",
        dataIndex: "avatar_url",
        render: (url: string) => <Avatar src={url} shape="square" size={48} />,
      },
      {
        title: "微信昵称",
        dataIndex: "nickname",
      },
      {
        title: "姓名",
        dataIndex: "name",
      },
      {
        title: "帐号",
        dataIndex: "account",
      },
      {
        title: "联系电话",
        dataIndex: "contact",
      },
      {
        title: "状态",
        render: (item: IDispatcher) => {
          if (item.locked) {
            return "已锁定";
          }
          if (item.deleted) {
            return "已删除";
          }
          if (item.openid === "") {
            return "未绑定";
          }

          return "正常";
        },
      },
      {
        title: "操作",
        render: (item: IDispatcher) => {
          let items = [];
          if (item.openid === "") {
            items.push(
              <Button
                key={`${Math.random()}`}
                type="ghost"
                onClick={() => showQrImage(item)}
              >
                绑定微信
              </Button>
            );
            items.push(<Divider key={`${Math.random()}`} type="vertical" />);
          } else {
            items.push(
              <Tooltip title="暂未完成，下个版本实现">
                <Button key={`${Math.random()}`} type="ghost">
                  修改密码
                </Button>
              </Tooltip>
            );
            items.push(<Divider type="vertical" key={`${Math.random()}`} />);
          }

          // TODO 锁定/删除操作以及逆操作
          items.push(<Button key={`${Math.random()}`}>调度情况</Button>);

          return <div>{items}</div>;
        },
      },
    ];
  }, [showQrImage]);

  const pageData = React.useCallback(
    (page: number, size: number) => {
      if (tbSpin[0]) {
        return;
      }
      tbSpin[1](true);
      queryDispatcherPage(page, size)
        .then((res) => {
          return res.json();
        })
        .then((res: ServerRes<PageData<IDispatcher>>) => {
          if (res.Error === "C000000") {
            props.PageDispatcher(res.Result);
            message.success("获取成功");
          }
        })
        .catch((err: Error) => {
          console.log(err);
          message.error(`获取失败：${err.message}`);
        })
        .finally(() => {
          tbSpin[1](false);
        });
    },
    [tbSpin, props]
  );

  React.useEffect(() => {
    if (!initialed[0] && props.Dispatcher.page === 0) {
      pageData(props.Dispatcher.page + 1, props.Dispatcher.size);
      initialed[1](true);
    }
  }, [initialed, props, pageData]);

  return (
    <div>
      <PageHeader
        title="调度员管理"
        ghost={false}
        extra={
          <Button
            type="primary"
            size="large"
            icon={<PlusCircleFilled />}
            onClick={() => props.history.push("/dispatcher/add")}
          >
            添加调度员
          </Button>
        }
      />
      <Divider />
      <Table
        columns={colunmes}
        dataSource={props.Dispatcher.payload}
        rowKey="did"
        pagination={{
          total: props.Dispatcher.total,
          current: props.Dispatcher.page,
          pageSize: props.Dispatcher.size,
          showQuickJumper: true,
          showSizeChanger: true,
          onChange: pageData,
        }}
        loading={tbSpin[0]}
      />
    </div>
  );
};

export default withRouter(connect(s2p, d2p)(Dispatcher));
