import { PageData, PageAction } from "../constants/globa";
import { IServeyor, PAGE_SERVEYOR } from "../constants/serveyor";

const initialState: PageData<IServeyor> = {
  page: 0,
  size: 20,
  total: 0,
  payload: [],
  statistic: {
    today: 0,
    history: 0,
  },
};

export const Serveyor = (
  state = initialState,
  action: PageAction<IServeyor>
) => {
  switch (action.type) {
    case PAGE_SERVEYOR:
      return action.page;
    default:
      return state;
  }
};
