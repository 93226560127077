import moment from "moment";
import { login_args } from "../constants/login";
import { RT } from "./request";

// export const host = "http://192.168.0.99:9090";
export const host = "https://v2.applet.trailerman.cn";

export function login(data: login_args): Promise<Response> {
  const headers = new Headers();
  headers.append("content-type", "application/json");
  const url = `${host}/api/cs/auth/login`;

  const request = new Request(url, {
    headers,
    method: "POST",
    body: JSON.stringify(data),
    mode: "cors",
  });

  return RT(request, 5000);
}

export function pageService(
  page: number = 1,
  size: number = 20,
  args: moment.Moment[]
): Promise<Response> {
  let url = `${host}/api/cs/service/page/${page}/${size}`;

  const range = args
    .map((m) => Math.ceil(m.toDate().getTime() / 1000))
    .join(",");
  url = `${url}?moments=${range}`;

  const request = new Request(url, {
    method: "GET",
  });

  return RT(request, 5000);
}

export function queryServicePage(
  page: number = 1,
  size: number = 20,
  args: { license: string }
): Promise<Response> {
  let url = `${host}/api/cs/service/query/${page}/${size}?license=${args.license}`;

  const request = new Request(url, {
    method: "GET",
  });

  return RT(request, 5000);
}

export function getServiceDetails(sid: string): Promise<Response> {
  const url = `${host}/api/cs/service/details/${sid}`;

  const request = new Request(url, {
    method: "GET",
  });

  return RT(request, 5000);
}

export function getServiceItems(data: { sid: string }): Promise<Response> {
  const headers = new Headers();
  headers.append("content-type", "application/json");
  const url = `${host}/api/cs/service/items`;

  const request = new Request(url, {
    headers,
    method: "POST",
    body: JSON.stringify(data),
    mode: "cors",
  });

  return RT(request, 5000);
}

export function refund(sid: string, cipher: string) {
  const headers = new Headers();
  headers.append("content-type", "application/json");
  const url = `${host}/api/cs/payment/refund`;

  const request = new Request(url, {
    headers,
    method: "POST",
    body: JSON.stringify({ sid, cipher }),
    mode: "cors",
  });

  return RT(request, 5000);
}

export function pagePayment(
  page: number = 1,
  size: number = 20,
  moments: moment.Moment[]
): Promise<Response> {
  const range = moments
    .map((m) => Math.ceil(m.toDate().getTime() / 1000))
    .join(",");
  const url = `${host}/api/cs/payment/page/${page}/${size}?moments=${range}`;

  const request = new Request(url, {
    method: "GET",
    mode: "cors",
  });

  return RT(request, 5000);
}

export function queryPaymentPage(
  page: number = 1,
  size: number = 20,
  args: { license: string }
): Promise<Response> {
  let url = `${host}/api/cs/payment/query/${page}/${size}?license=${args.license}`;

  const request = new Request(url, {
    method: "GET",
  });

  return RT(request, 5000);
}

export function pageCredit(
  page: number = 1,
  size: number = 20,
  moments: moment.Moment[]
): Promise<Response> {
  const range = moments
    .map((m) => Math.ceil(m.toDate().getTime() / 1000))
    .join(",");
  const url = `${host}/api/cs/credit/page/${page}/${size}?moments=${range}`;

  const request = new Request(url, {
    method: "GET",
    mode: "cors",
  });

  return RT(request, 5000);
}

export function queryCreditPage(
  page: number = 1,
  size: number = 20,
  args: string
): Promise<Response> {
  let url = `${host}/api/cs/credit/query/${page}/${size}?operator=${args}`;

  const request = new Request(url, {
    method: "GET",
  });

  return RT(request, 5000);
}

export function getConfigs(): Promise<Response> {
  const url = `${host}/api/cs/configs`;

  const request = new Request(url, {
    method: "GET",
    mode: "cors",
  });

  return RT(request, 5000);
}

export function addNotification(data: {
  name: string;
  phone: string;
  tags: string[];
}): Promise<Response> {
  const headers = new Headers();
  headers.append("content-type", "application/json");
  const url = `${host}/api/cs/config/notification`;

  const request = new Request(url, {
    headers,
    method: "POST",
    body: JSON.stringify(data),
    mode: "cors",
  });

  return RT(request, 5000);
}

export function removeNotification(data: { id: number }): Promise<Response> {
  const headers = new Headers();
  headers.append("content-type", "application/json");
  const url = `${host}/api/cs/config/remove/notification`;

  const request = new Request(url, {
    headers,
    method: "POST",
    body: JSON.stringify(data),
    mode: "cors",
  });

  return RT(request, 5000);
}

export function pageBuyer(
  page: number = 1,
  size: number = 20
): Promise<Response> {
  const url = `${host}/api/cs/buyer/page/${page}/${size}`;

  const request = new Request(url, {
    method: "GET",
    mode: "cors",
  });

  return RT(request, 5000);
}

export function addBuyer(data: {
  name: string;
  password: string;
}): Promise<Response> {
  const headers = new Headers();
  headers.append("content-type", "application/json");
  const url = `${host}/api/cs/add/buyer`;

  const request = new Request(url, {
    headers,
    method: "POST",
    body: JSON.stringify(data),
    mode: "cors",
  });

  return RT(request, 5000);
}

export function toggleBuyingOnCredit(data: {
  buying_on_credit: boolean;
  name: string;
  id: number;
}): Promise<Response> {
  const headers = new Headers();
  headers.append("content-type", "application/json");
  const url = `${host}/api/cs/buyer/buying`;

  const request = new Request(url, {
    headers,
    method: "POST",
    body: JSON.stringify(data),
    mode: "cors",
  });

  return RT(request, 5000);
}

export function deBuyingOnCredit(sid: string, id: number) {
  const headers = new Headers();
  headers.append("content-type", "application/json");
  const url = `${host}/api/cs/buyer/debuying`;

  const request = new Request(url, {
    headers,
    method: "POST",
    body: JSON.stringify({ sid, id }),
    mode: "cors",
  });

  return RT(request, 5000);
}

export function changeBuyerPassword(data: {
  name: string;
  id: number;
  password: string;
}): Promise<Response> {
  const headers = new Headers();
  headers.append("content-type", "application/json");
  const url = `${host}/api/cs/buyer/chpass`;

  const request = new Request(url, {
    headers,
    method: "POST",
    body: JSON.stringify(data),
    mode: "cors",
  });

  return RT(request, 5000);
}

// 30天服务趋势
export function sservice() {
  const url = `${host}/api/cs/ss/service`;

  const request = new Request(url, {
    method: "GET",
  });

  return RT(request, 5000);
}

// 30天挂帐趋势
export function sbuyingOnCredit() {
  const url = `${host}/api/cs/ss/guazhang`;

  const request = new Request(url, {
    method: "GET",
  });

  return RT(request, 5000);
}

// 30天支付趋势
export function spayment() {
  const url = `${host}/api/cs/ss/payment`;

  const request = new Request(url, {
    method: "GET",
  });

  return RT(request, 5000);
}

// 删除服务
export function deleteService(sid: string, id: number) {
  const url = `${host}/api/cs/delete/service`;
  const headers = new Headers();
  headers.append("content-type", "application/json");

  const request = new Request(url, {
    headers,
    method: "POST",
    body: JSON.stringify({ sid, id }),
    mode: "cors",
  });

  return RT(request, 5000);
}

export function queryDrivers(page: number, size: number, params = "") {
  const url = `${host}/api/cs/driver/query/${page}/${size}?params=${params}`;

  const request = new Request(url, {
    method: "GET",
  });

  return RT(request, 5000);
}

export function confirmDriver(data: { openid: string; confirm: -1 | 0 | 1 }) {
  const url = `${host}/api/cs/driver/verify`;
  const headers = new Headers();
  headers.append("content-type", "application/json");

  const request = new Request(url, {
    method: "POST",
    body: JSON.stringify(data),
    mode: "cors",
    headers,
  });

  return RT(request, 5000);
}

export function exportExcel(start: number, end: number) {
  const url = `${host}/api/cs/excel/service?t=${start},${end}`;
  const request = new Request(url, {
    method: "GET",
  });

  return RT(request, 50000);
}
