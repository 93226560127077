import {
  ApiOutlined,
  ControlOutlined,
  CrownOutlined,
  DeploymentUnitOutlined,
  EyeOutlined,
  HeartOutlined,
  IdcardOutlined,
  InsuranceOutlined,
  MessageFilled,
  NodeCollapseOutlined,
  SettingFilled,
  TransactionOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React from "react";
import { connect } from "react-redux";
import {
  Link,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import { IUser } from "../../constants/login";
import logo from "../../images/trailerman.jpg";
import BuyerComp from "./components/config/buyer";
import ChargeConfigComp from "./components/config/charge";
import NotificationConfigComp from "./components/config/notification";
import AddDispatcherComp from "./components/dispatcher/add";
import DispatcherComp from "./components/dispatcher/dispatcher";
import DriversComp from "./components/driver/drivers";
import IndexComp from "./components/index";
import AddPartnerComp from "./components/partner/add";
import PartnerComp from "./components/partner/partner";
import ServeyorComp from "./components/serveyor/serveyor";
import CreditComp from "./components/service/credit";
import PaymentComp from "./components/service/payment";
import ServiceComp from "./components/service/service";
import ServiceDetailsComp from "./components/service/service_details";
import ExcelComp from "./components/service/excel";

const s2p = ({ User }: { User: IUser }) => ({
  User,
});

type IProps = ReturnType<typeof s2p> & RouteComponentProps;

const Index: React.FC<IProps> = (props: IProps) => {
  React.useEffect(() => {
    if (props.User == null) {
      props.history.push("/login");
    }
  }, [props]);

  return (
    <Layout className="index-layout">
      <Layout.Header className="index-header">
        <img src={logo} alt="拖车侠" />
      </Layout.Header>
      <Layout>
        <Layout.Sider theme="light" style={{ width: "240px" }}>
          <Menu mode="inline">
            <Menu.Item>
              <Link to="/">首页</Link>
            </Menu.Item>
            <Menu.SubMenu
              key="service"
              title={
                <span>
                  <HeartOutlined /> 服务
                </span>
              }
            >
              <Menu.Item key="service">
                <Link to="/service/service">
                  <UnorderedListOutlined /> 服务列表
                </Link>
              </Menu.Item>
              <Menu.Item key="payment">
                <Link to="/service/payment">
                  <TransactionOutlined />
                  支付
                </Link>
              </Menu.Item>
              <Menu.Item key="buying-credit">
                <Link to="/service/credit">
                  <MessageFilled />
                  挂帐
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            {/* <Menu.SubMenu
                key="dispatcher"
                title={
                <span>
                <ControlOutlined />
                调度员
                </span>
                }
                >
                <Menu.Item key="dispatcher-list">
                <Link to="/dispatcher/list">
                <ApiOutlined /> 调度员列表
                </Link>
                </Menu.Item>
                </Menu.SubMenu> */}
            <Menu.SubMenu
              key="drivers"
              title={
                <span>
                  <DeploymentUnitOutlined />
                  驾驶员
                </span>
              }
            >
              <Menu.Item key="list">
                <Link to="/drivers/list">
                  <IdcardOutlined /> 驾驶员列表
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            {/* <Menu.SubMenu
                key="partner"
                title={
                <span>
                <CrownOutlined />
                合作伙伴
                </span>
                }
                >
                <Menu.Item key="partner-list">
                <Link to="/partner/list">
                <InsuranceOutlined /> 合作伙伴列表
                </Link>
                </Menu.Item>
                <Menu.Item key="surveyor-list">
                <Link to="/serveyor/list">
                <NodeCollapseOutlined /> 查勘员列表
                </Link>
                </Menu.Item>
                <Menu.Item key="inspect-list">
                <Link to="/inspect/list">
                <EyeOutlined /> 督查员列表
                </Link>
                </Menu.Item>
                </Menu.SubMenu> */}
            <Menu.SubMenu
              key="config"
              title={
                <span>
                  <SettingFilled /> 配置
                </span>
              }
            >
              <Menu.Item key="buyer">
                <Link to="/config/buyer">
                  <TransactionOutlined />
                  挂账人配置{" "}
                </Link>
              </Menu.Item>
              <Menu.Item key="charge">
                <Link to="/config/charge">
                  <TransactionOutlined />
                  收费配置{" "}
                </Link>
              </Menu.Item>
              <Menu.Item key="sms">
                <Link to="/config/notification">
                  <MessageFilled />
                  通知配置
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        </Layout.Sider>
        <Layout.Content>
          <div className="index-content">
            <Switch>
              <Route path="/" exact={true} component={IndexComp} />
              <Route path="/config/charge" component={ChargeConfigComp} />
              <Route path="/config/buyer" component={BuyerComp} />
              <Route
                path="/config/notification"
                component={NotificationConfigComp}
              />
              <Route path="/service/service" component={ServiceComp} />
              <Route
                path="/service/details/:sid"
                component={ServiceDetailsComp}
              />
              <Route path="/service/payment" component={PaymentComp} />
              <Route path="/service/credit" component={CreditComp} />
              <Route path="/service/excel" component={ExcelComp} />
              <Route path="/drivers/list" component={DriversComp} />
              <Route path="/partner/list" component={PartnerComp} />
              <Route path="/partner/add" component={AddPartnerComp} />
              <Route path="/serveyor/list" component={ServeyorComp} />
              <Route path="/dispatcher/list" component={DispatcherComp} />
              <Route path="/dispatcher/add" component={AddDispatcherComp} />
            </Switch>
          </div>
        </Layout.Content>
      </Layout>

      <Layout.Footer className="index-footer">
        © 2017-2020 技术支持: 名无
      </Layout.Footer>
    </Layout>
  );
};

export default connect(s2p)(withRouter(Index));
