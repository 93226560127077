import { PageData, PageAction } from "../constants/globa";
import {
  Service as IService,
  PAGE_SERVICE,
  PATCH_SERVICE,
} from "../constants/service";

type PatchAction = {
  type: string;
  service: IService;
};

const initialState: PageData<IService> = {
  page: 0,
  size: 20,
  total: 0,
  payload: [],
  statistic: {
    today: 0,
    history: 0,
  },
};

export const Service = (
  state = initialState,
  action: PageAction<IService> | PatchAction
) => {
  switch (action.type) {
    case PAGE_SERVICE:
      let act = action as PageAction<IService>;
      return act.page;
    case PATCH_SERVICE: {
      let nextPayload = [...state.payload];
      let act = action as PatchAction;
      for (let i = 0; i < nextPayload.length; i++) {
        if (nextPayload[i].sid === act.service.sid) {
          nextPayload[i] = act.service;
          break;
        }
      }
      return { ...state, ...{ payload: nextPayload } };
    }
    default:
      return state;
  }
};
