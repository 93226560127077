export interface Service {
  id: number;
  sid: string;
  openid: string;
  recorder: string;
  payer: string;
  name: string;
  phone: string;
  gender: string;
  license: string;
  model: string;
  status: number;
  created_at: number;
}

// 现场救援难度
export type Difficulty = "normal" | "hard" | "customize";

// 车型大小
export type VehicheSize = "小型车" | "中型车" | "中大型车";

export type LocationType = "起始地" | "目的地";

// 辅助轮
export interface IWheel {
  id?: number; // 保存到数据库后才有
  sid: string;
  total_fee?: number; // 总价
  count: number; // 数量
}

// 现场救援
export interface ISceneRescue {
  id?: number;
  sid?: string; // 对应服务的sid
  difficulty: Difficulty; // 难度
  total_fee?: number; // 价格
}

// 地库救援
export interface IBasement {
  id?: number;
  sid: string;
  total_fee?: number; // 价格
}

// 位置
export interface ILocation {
  id?: number;
  sid: string;
  vid: number;
  cid: number;
  type: LocationType; // 位置类型：起始地或目的地
  longitude: string; // 经度
  latitude: string; // 纬度
  province: string; // 省份
  city: string; // 城市
  district: string; // 区县
  address: string; // 地址
  name: string; // 地点名称
}

// 车辆拖运
export interface IVehicleTransport {
  id?: number;
  sid: string;
  sort: number; // 排序
  model: string; // 车型
  license: string; // 车牌号码
  size: VehicheSize; // 车辆大小
  starting: number; // 起始地
  destination: number; // 目的地
  distance: number; // 距离(米)
  created_at: number; // 添加时间(unix时间戳)
  total_fee: number; // 费用
  starting_fee: boolean; // 是否收起步价
}

// 随车吊
export interface ICrane {
  id?: number;
  sid: string; // 对应服务的sid
  model: string; // 车型
  license: string; // 车牌号
  starting: number; // 起始地
  destination: number; // 目的地
  distance: number; // 距离(米)
  created_at: number; // 添加时间(unix时间戳)
  total_fee: number; // 费用
}

// 高速救援附加费
export interface IHighwaySurcharge {
  id: number;
  sid: string;
  total_fee: number;
}

// 接送服务
export interface IPickUp {
  id: number;
  sid: string;
  starting: number;
  destination: number;
  distance: number;
  total_fee: number;
}

export interface IServiceItems {
  crane?: ICrane; // 随车吊
  wheel?: IWheel; // 辅助轮
  sceneRescue?: ISceneRescue; // 现场救援
  basement?: IBasement; // 地库救援
  locations: ILocation[];
  vehicleTransports: IVehicleTransport[]; // 车辆运输
  highwaySurcharge?: IHighwaySurcharge; // 高速救援附加费
  pickup?: IPickUp; // 接送服务
}

export const STATUS = [
  "未知",
  "服务已请求",
  "服务已支付",
  "服务已挂帐",
  "服务已退款",
];

export const PAGE_SERVICE = "PAGE_SERVICE";
export const PATCH_SERVICE = "PATCH_SERVICE";
