import React from "react";
import DataSet from "@antv/data-set";
import G2 from "@antv/g2";

import { spayment } from "../../../../utils/api";

const Service: React.FC = () => {
  React.useEffect(() => {
    spayment()
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        const data = json.Result.Items;
        data.map((d) => (d.total = d.total / 100));

        const chart = new G2.Chart({
          container: "container2",
          forceFit: true,
          height: 250,
          padding: [60, 20, 20, 60],
        });
        const ds = new DataSet();
        const dv = ds.createView().source(data);

        chart.scale("total", {
          alias: "支付金额",
        });

        chart.axis("date", {
          label: {
            textStyle: {
              fill: "#aaaaaa",
            },
          },
          tickLine: {
            length: 0,
          },
        });
        chart.axis("total", {
          label: {
            textStyle: {
              fill: "#333",
            },
          },
          title: {
            offset: 50,
          },
        });

        const view1 = chart.view();
        view1.source(data);

        view1
          .interval()
          .position("date*total")
          .opacity(1)
          .label("total", {
            useHtml: true,
            htmlTemplate: (text, item) => {
              return `<span class="g2-label-item"><p class="g2-label-item-value">${item.point.count}次</p></div>`;
            },
          });

        const view2 = chart.view();
        view2.axis(false);
        view2.source(dv);
        view2
          .line()
          .position("date*total")
          .style({
            stroke: "#969696",
            lineDash: [3, 3],
          })
          .tooltip(false);
        view2.guide().text({
          content: "",
          position: ["min", "min"],
          style: {
            fill: "#8c8c8c",
            fontSize: 14,
            fontWeight: 300,
          },
          offsetY: -80,
        });

        chart.render();

        let t;
        window.addEventListener("resize", () => {
          t = setTimeout(() => {
            clearTimeout(t);
            chart.render();
          }, 200);
        });
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return <div id="container2" style={{ width: "100%", height: "250px" }} />;
};

export default Service;
