import {
  AccountBookTwoTone,
  ScheduleTwoTone,
  SearchOutlined,
  StarTwoTone,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  message,
  PageHeader,
  Table,
  Tag,
} from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";
import dayjs from "dayjs";
import moment from "moment";
import "moment/locale/zh-cn";
import React from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { pageCredit } from "../../../../actions/credit";
import { ICredit } from "../../../../constants/credit";
import { PageData } from "../../../../constants/globa";
import { IPayment } from "../../../../constants/payment";
import {
  host,
  pageCredit as RequestPage,
  queryCreditPage,
} from "../../../../utils/api";
import { ServerRes } from "../../../../utils/request";
import SBuyingOnCredit from "../statistics/buyinh_on_credit";

const { RangePicker } = DatePicker;

const s2p = ({ Credit }: { Credit: PageData<ICredit> }) => ({
  Credit,
});

const d2p = (dispatch: any) => ({
  pageCredit: (page: PageData<ICredit>) => dispatch(pageCredit(page)),
});

type IProps = ReturnType<typeof s2p> &
  ReturnType<typeof d2p> &
  RouteComponentProps;

const Payment: React.FC<IProps> = (props: IProps) => {
  const [itd, setItd] = React.useState(false);
  const [sp, setSp] = React.useState(false);
  const [moments, setMoments] = React.useState<moment.Moment[]>([]);

  let filters = React.useMemo(() => {
    return [
      {
        text: "贵阳人保",
        value: 3,
      },
      {
        text: "毕节人保",
        value: 4,
      },
      {
        text: "黔路捷",
        value: 5,
      },
      {
        text: "白云神龙",
        value: 6,
      },
      {
        text: "名匠车坊",
        value: 7,
      },
      {
        text: "毕节佰润奥迪",
        value: 8,
      },
      {
        text: "安诚保险",
        value: 9,
      },
      {
        text: "黔南人保",
        value: 10,
      },
      {
        text: "君爵立华",
        value: 11,
      },
    ];
  }, []);

  let [checkedIds, setCheckedIds] = React.useState<Array<number>>([]);

  const fetchData = React.useCallback(
    (page, size, moments) => {
      setSp(true);

      let action = typeof moments == "string" ? queryCreditPage : RequestPage;

      action(page, size, moments)
        .then((res: Response) => {
          return res.json();
        })
        .then((res: ServerRes<PageData<ICredit>>) => {
          if (res.Error !== 0) {
            return;
          }
          props.pageCredit(res.Result);
          setItd(true);
        })
        .catch((e) => {
          message.error(e);
        })
        .finally(() => {
          setSp(false);
          setCheckedIds([]);
        });
    },
    [setSp, setCheckedIds, props]
  );

  const queryData = React.useCallback(
    (page: number, size: number, operators: number[]) => {
      setSp(true);
      queryCreditPage(page, size, operators.join(","))
        .then((res: Response) => {
          return res.json();
        })
        .then((res: ServerRes<PageData<ICredit>>) => {
          if (res.Error !== 0) {
            return;
          }
          props.pageCredit(res.Result);
          setItd(true);
        })
        .catch((e) => {
          message.error(e);
        })
        .finally(() => {
          setSp(false);
        });
    },
    [setSp, props]
  );

  React.useEffect(() => {
    document.title = "挂帐列表";
    if (itd || props.Credit.page > 0) {
      return;
    }
    fetchData(props.Credit.page + 1, props.Credit.size, moments);
  }, [itd, setItd, props, fetchData, moments]);

  const handleOperatorChange = React.useCallback(
    (id: number, checked: boolean) => {
      let next = new Set(checkedIds);
      if (checked) {
        next.add(id);
      } else {
        next.delete(id);
      }
      setCheckedIds([...next]);
    },
    [checkedIds, setCheckedIds]
  );

  const columns = React.useMemo(() => {
    return [
      {
        title: "服务二维码",
        render: (item: ICredit) =>
          item.sid ? (
            <Avatar
              size={48}
              src={`${host}/api/wx/service/image/${item.sid}`}
            />
          ) : (
            "即时支付"
          ),
        width: "84px",
      },
      {
        title: "车牌号码",
        dataIndex: "license",
      },
      {
        key: "total_fee",
        title: "金额",
        dataIndex: "total_fee",
        render: (fee: number) => <span>￥{fee / 100}</span>,
      },
      {
        key: "operator_name",
        title: "挂帐人",
        dataIndex: "operator_name",
        filterDropdown: (p: FilterDropdownProps) => (
          <div style={{ padding: 8 }}>
            {filters.map((f) => (
              <div style={{ padding: "8px 0" }}>
                <Checkbox
                  checked={checkedIds.includes(f.value)}
                  onChange={(v) =>
                    handleOperatorChange(f.value, v.target.checked)
                  }
                  key={f.value}
                >
                  {f.text}
                </Checkbox>
              </div>
            ))}
            <div style={{ margin: "4px 0" }}>
              <Button
                type="primary"
                onClick={() => {
                  queryData(1, props.Credit.size, checkedIds);
                  p.confirm();
                }}
              >
                筛选
              </Button>
              <Divider type="vertical" />
              <Button
                type="default"
                onClick={() => {
                  setCheckedIds([]);
                  p.clearFilters();
                  fetchData(1, props.Credit.size, []);
                }}
              >
                重置
              </Button>
            </div>
          </div>
        ),
      },
      {
        key: "created_at",
        title: () => {
          if (moments.length === 2) {
            let start = moments[0].format("YYYY-MM-DD ");
            let end = moments[1].format("YYYY-MM-DD ");
            return `挂帐时间 (${start} - ${end})`;
          }
          return "挂帐时间";
        },
        dataIndex: "created_at",
        render: (time: number) => (
          <span>
            {dayjs(time * 1000).format("YYYY-MM-DD HH:mm:ss")}{" "}
            {dayjs(time * 1000).isSame(dayjs(), "day") ? (
              <Tag color="#1890ff">今天</Tag>
            ) : null}
          </span>
        ),
        filterDropdown: (p: FilterDropdownProps) => (
          <div style={{ padding: 8 }}>
            <RangePicker
              ranges={{
                今天: [moment().startOf("day"), moment()],
                本周: [moment().startOf("week"), moment()],
                本月: [moment().startOf("month"), moment()],
                本季: [moment().startOf("quarter"), moment()],
                今年: [moment().startOf("year"), moment()],
              }}
              value={moments as [moment.Moment, moment.Moment]}
              onChange={(mnts: moment.Moment[]) => {
                setMoments(mnts == null ? [] : mnts);
                if (mnts.length === 2) {
                  mnts[0] = mnts[0].startOf("day");
                  mnts[1] = mnts[1].endOf("day");
                }
                fetchData(1, props.Credit.size, mnts == null ? [] : mnts);
                p.confirm();
              }}
            />
            <Button
              type="default"
              icon={<SearchOutlined />}
              style={{ width: 90, marginLeft: 8 }}
              onClick={() => {
                if (moments.length === 2) {
                  setMoments([]);
                  p.confirm();
                  fetchData(1, props.Credit.size, []);
                }
              }}
            >
              重置
            </Button>
          </div>
        ),
      },
      {
        title: "操作",
        render: (item: IPayment) => (
          <span>
            <Link to={`/service/details/${item.sid}`}>查看服务详情</Link>
          </span>
        ),
      },
    ];
  }, [
    moments,
    setMoments,
    fetchData,
    filters,
    handleOperatorChange,
    checkedIds,
    setCheckedIds,
    props.Credit.size,
    queryData,
  ]);

  let pageTotal = 0;

  props.Credit.payload.map((p) => (pageTotal += p.total_fee / 100));

  return (
    <div>
      <PageHeader
        ghost={false}
        title="挂帐列表"
        extra={[
          <Button
            key="1"
            size="large"
            type="dashed"
            shape="round"
            icon={<StarTwoTone color="#52c41a" />}
          >
            当前页面： ￥{pageTotal}元
          </Button>,
          <Button
            key="2"
            size="large"
            type="dashed"
            shape="round"
            icon={<ScheduleTwoTone />}
          >
            今天：￥{props.Credit?.statistic?.today / 100}元
          </Button>,
          <Button
            key="3"
            size="large"
            type="dashed"
            shape="round"
            icon={<AccountBookTwoTone />}
          >
            所有：￥{props.Credit?.statistic?.history / 100}元
          </Button>,
          <Button
            key="4"
            size="large"
            type="primary"
            icon={<SyncOutlined spin={sp} />}
            onClick={() => fetchData(1, props.Credit.size, [])}
          >
            刷新
          </Button>,
        ]}
      />

      <div
        style={{
          backgroundColor: "#FFF",
          textAlign: "right",
          paddingRight: "24px",
        }}
      >
        <h3>30天挂帐趋势</h3>
        <SBuyingOnCredit />
      </div>
      <Divider />
      <Table
        loading={sp}
        dataSource={props.Credit.payload}
        columns={columns}
        rowKey="sid"
        pagination={{
          total: props.Credit.total,
          pageSize: props.Credit.size,
          current: props.Credit.page,
          onChange: (page, size) => {
            if (checkedIds.length === 0) {
              fetchData(page, size, moments);
            } else {
              console.log(page, size, checkedIds.join(","));
              fetchData(page, size, checkedIds.join(","));
            }
          },
          onShowSizeChange: (page, size) => {
            if (checkedIds.length === 0) {
              fetchData(page, size, moments);
            } else {
              fetchData(page, size, checkedIds.join(","));
            }
          },
          showTotal: (total: number, range: [number, number]) => (
            <span>
              共{total}条记录，当前第{range[0]} - {range[1]}条
            </span>
          ),
        }}
      />
    </div>
  );
};

export default connect(s2p, d2p)(withRouter(Payment));
