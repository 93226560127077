export const DownloadFile = (response: Response, name: string) => {
  response
    .blob()
    .then((blob) => {
      //下载
      let blobUrl = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = blobUrl;
      a.target = "_blank";
      a.style.display = "none";
      document.body.appendChild(a);
      a.download = name;
      a.click();
      window.URL.revokeObjectURL(blobUrl);
      document.body.removeChild(a);
    })
    .catch((e) => {
      throw e;
    });
};
