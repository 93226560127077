import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  message,
  PageHeader,
  Table,
  Modal,
  Form,
  Input,
  Spin,
  Switch,
} from "antd";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import { pageBuyer } from "../../../../actions/buyer";
import { IBuyer } from "../../../../constants/buyer";
import { PageData } from "../../../../constants/globa";
import {
  pageBuyer as ReqBuyer,
  addBuyer,
  toggleBuyingOnCredit,
  changeBuyerPassword,
} from "../../../../utils/api";
import { ServerRes } from "../../../../utils/request";
import { Store } from "antd/lib/form/interface";

const s2p = ({ Buyer }: { Buyer: PageData<IBuyer> }) => ({
  Buyer,
});

const d2p = (dispatch: any) => ({
  pageBuyer: (page: PageData<IBuyer>) => dispatch(pageBuyer(page)),
});

type IProps = ReturnType<typeof s2p> &
  ReturnType<typeof d2p> &
  RouteComponentProps;

const Buyer: React.FC<IProps> = (props: IProps) => {
  const [sp, setSp] = React.useState(false);
  const [swt, setSwt] = React.useState(false);
  const [addModel, setAddModel] = React.useState(false);
  const [chpModel, setChpModel] = React.useState(false);
  const [req, setReq] = React.useState(false);
  const [chUser, setChUser] = React.useState<IBuyer>();

  const fetchData = React.useCallback(
    (page: number, size: number) => {
      setSp(true);

      ReqBuyer(page, size)
        .then((res) => {
          return res.json();
        })
        .then((res: ServerRes<PageData<IBuyer>>) => {
          if (res.Error === 0) {
            props.pageBuyer(res.Result);
            /* props.setConfigs(res.Result); */
          }
        })
        .catch((e) => {
          message.error(`获取挂帐人配置失败：${e}`);
        })
        .finally(() => {
          setSp(false);
        });
    },
    [setSp, props]
  );

  React.useEffect(() => {
    document.title = "挂帐人设置";
    if (props.Buyer.page === 0) {
      fetchData(props.Buyer.page + 1, props.Buyer.size);
    }
  }, []);

  const handleItemBuyingOnCreditChange = React.useCallback(
    (ok: boolean, id: number, name: string) => {
      setSwt(true);

      toggleBuyingOnCredit({ buying_on_credit: ok, id: id, name: name })
        .then((res: Response) => {
          return res.json();
        })
        .then((res: ServerRes<null>) => {
          if (res.Error == 0) {
            message.success("修改成功");
            return;
          }
          message.error(`修改失败：${res.Error}`);
        })
        .catch((e) => {
          message.error(`修改失败：${e}`);
        })
        .finally(() => {
          setSwt(false);
        });
    },
    [setSwt]
  );

  const columns = React.useMemo(() => {
    return [
      {
        dataIndex: "name",
        title: "姓名",
      },
      {
        title: "是否可以挂帐",
        render: (item: IBuyer) => (
          <Switch
            checkedChildren="是"
            unCheckedChildren="否"
            defaultChecked={item.buying_on_credit}
            loading={swt}
            onChange={(ok: boolean) =>
              handleItemBuyingOnCreditChange(ok, item.id, item.name)
            }
          />
        ),
      },
      {
        title: "操作",
        render: (item: IBuyer) => (
          <span>
            <Link
              to="#"
              onClick={() => {
                setChUser(item);
                setChpModel(true);
              }}
            >
              修改密码
            </Link>
          </span>
        ),
      },
    ];
  }, [handleItemBuyingOnCreditChange, swt, setChUser, setChpModel]);

  const formLayout = React.useMemo(() => {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };
  }, []);

  const [addForm] = Form.useForm();
  const [chpForm] = Form.useForm();

  const handleChPass = React.useCallback(
    (values: Store) => {
      if (chUser === null || chUser === undefined) {
        return;
      }

      setReq(true);
      changeBuyerPassword({
        name: chUser.name,
        id: chUser.id,
        password: values["p1"],
      })
        .then((res: Response) => {
          return res.json();
        })
        .then((res: ServerRes<null>) => {
          if (res.Error == 0) {
            message.success("修改成功");
            setChUser(null);
            setChpModel(false);
            chpForm.resetFields();
            return;
          }
          message.error(`修改失败：${res.Error}`);
        })
        .catch((e) => {
          message.error(`修改失败：${e}`);
        })
        .finally(() => {
          setReq(false);
        });
    },
    [chUser, setChUser, setReq, chpForm]
  );

  const handleAddSubmit = React.useCallback(
    (values: Store) => {
      setReq(true);
      addBuyer({ ...values } as { name: string; password: string })
        .then((res) => {
          return res.json();
        })
        .then((res: ServerRes<IBuyer>) => {
          if (res.Error === 0) {
            const Buyer = { ...props.Buyer };
            Buyer.payload = [...[res.Result], ...Buyer.payload];
            Buyer.total = Buyer.total + 1;
            addForm.resetFields();
            setAddModel(false);
            message.success("添加成功");
            props.pageBuyer(Buyer);
          } else {
            message.error(`添加通知人失败：${res.Desc}`);
          }
        })
        .catch((e: any) => {
          console.error(`Error: ${e}`);
          message.error(`添加通知人失败：${e}`);
        })
        .finally(() => {
          setReq(false);
        });
    },
    [setReq, addForm, props, setAddModel]
  );

  return (
    <div>
      <PageHeader
        title="挂帐人列表"
        ghost={false}
        extra={[
          <Button
            onClick={() => setAddModel(true)}
            key={1}
            type="primary"
            icon={<PlusOutlined />}
          >
            添加挂帐人
          </Button>,
        ]}
      />
      <Divider />
      <Table
        loading={sp}
        columns={columns}
        rowKey="id"
        dataSource={props.Buyer.payload}
        pagination={{
          total: props.Buyer.total,
          pageSize: props.Buyer.size,
          onChange: fetchData,
          onShowSizeChange: fetchData,
          showTotal: (total: number, range: [number, number]) => (
            <span>
              共{total}条记录，当前第{range[0]} - {range[1]}条
            </span>
          ),
        }}
      />

      <Modal
        title="添加挂帐人"
        visible={addModel}
        footer={null}
        onCancel={() => {
          setAddModel(false);
          setChUser(null);
        }}
      >
        <Spin tip="正在提交" spinning={req}>
          <Form {...formLayout} form={addForm} onFinish={handleAddSubmit}>
            <Form.Item
              label="挂帐人姓名"
              name="name"
              rules={[
                { required: true, message: "请输入挂帐人姓名" },
                {
                  pattern: /^[\s\S]*.*[^\s][\s\S]*$/,
                  message: "请输入挂帐人姓名",
                },
              ]}
            >
              <Input type="text" name="name" />
            </Form.Item>
            <Form.Item
              label="挂帐人密码"
              name="password"
              rules={[
                { required: true, message: "请输入挂帐人密码" },
                {
                  pattern: /^[a-zA-Z\d_]{6,16}$/,
                  message: "密码由数字、字母和下划线组成",
                },
              ]}
            >
              <Input.Password name="password" />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
              <Button type="primary" htmlType="submit">
                添加
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>

      <Modal
        title="修改挂帐人密码"
        visible={chpModel}
        footer={null}
        onCancel={() => setChpModel(false)}
      >
        <Spin tip="正在提交" spinning={req}>
          <Form {...formLayout} form={chpForm} onFinish={handleChPass}>
            <Form.Item
              label="新密码"
              name="p1"
              rules={[
                { required: true, message: "请输入挂帐人密码" },
                {
                  pattern: /^[a-zA-Z\d_]{6,16}$/,
                  message: "密码由数字、字母和下划线组成",
                },
              ]}
            >
              <Input.Password name="p1" />
            </Form.Item>
            <Form.Item
              label="确认新密码"
              name="p2"
              rules={[
                { required: true, message: "请输入确认密码" },
                ({ getFieldValue }) => ({
                  validator: (_, value: string) =>
                    !value || getFieldValue("p1") === value
                      ? Promise.resolve()
                      : Promise.reject("两次输入密码不一致"),
                }),
              ]}
            >
              <Input.Password name="p2" />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
              <Button type="primary" htmlType="submit">
                修改
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
};

export default connect(s2p, d2p)(withRouter(Buyer));
