export const PAGE_PAYMENT = 'PAGE_PAYMENT';

export interface IPayment {
    id: number;
    sid?: string;
    openid: string;
    remarks: string;
    total_fee: number;
    out_trade_no: string;
    transaction_id: string;
    start_at: number;
    ends_at: number;
    status: number;
}
