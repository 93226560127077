export const PAGE_CREDIT = "PAGE_CREDIT";

export interface ICredit {
  id: number;
  sid: string;
  total_fee: number;
  operator_id: number;
  operator_name: string;
  created_at: number;
}
