import { IBuyer, PAGE_BUYER } from "../constants/buyer";
import { PageData, PageAction } from "../constants/globa";

const initialaState: PageData<IBuyer> = {
    page: 0,
    size: 20,
    total: 0,
    payload: [],
    statistic: {
        today: 0,
        history: 0,
    },
}

export const Buyer = (state = initialaState, action: PageAction<IBuyer>) => {
    switch (action.type) {
        case PAGE_BUYER:
            return action.page;
        default:
            return state;
    }
}
