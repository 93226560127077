export const PAGE_SERVEYOR = "PAGE_SERVEYOR";

// 调度员数据结构
export interface IServeyor {
  id: number;
  did: string;
  name: string;
  contact: string;
  company_id: string;
  company_name: string;
  openid: string;
  nickname: string;
  avatar_url: string;
}
